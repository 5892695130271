import cn from 'classnames';
import { useMemo, useState } from 'react';
import Section from '@/components/commons/Section';
import popularCategories from '@/settings/popular-categories';
import Card from './Card';
import Carousel from './Carousel';

const DesktopView = () => (
  <div className="mt-6 hidden gap-x-5 gap-y-8 md:grid md:grid-cols-4 lg:grid-cols-6">
    {popularCategories.map((category) => (
      <Card key={category.handle ?? category.pk} category={category} />
    ))}
  </div>
);

const MobileView = () => {
  const [seeMore, setSeeMore] = useState(false);

  const slicedPopularCategories = useMemo(
    () => (seeMore ? popularCategories : popularCategories.slice(0, 6)),
    [seeMore],
  );

  return (
    <div className="md:hidden">
      <div className="mt-6 grid grid-cols-3 gap-x-5 gap-y-8">
        {slicedPopularCategories.map((category) => (
          <Card key={category.handle ?? category.pk} category={category} />
        ))}
      </div>
      <button
        onClick={() => setSeeMore((prevState) => !prevState)}
        type="button"
        className="mx-auto mt-8 flex w-full items-center justify-center rounded-md border border-gray-700 py-2 text-sm xs:w-[250px]"
      >
        See {seeMore ? 'less' : 'more'}
      </button>
    </div>
  );
};

const PopularCategories = ({
  isHomePage,
  isCartPage,
}: {
  isHomePage?: boolean;
  isCartPage?: boolean;
}) => (
  <Section>
    <div className={cn({ 'reebelo-container': isHomePage })}>
      <h2 className="flex items-center text-lg font-semibold md:text-xl">
        Popular Categories
      </h2>
      {isHomePage ? (
        <>
          <DesktopView />
          <MobileView />
        </>
      ) : (
        <Carousel
          popularCategories={popularCategories}
          isCartPage={isCartPage}
        />
      )}
    </div>
  </Section>
);

export default PopularCategories;
