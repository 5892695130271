import type Sns from '@lambda/commons/apis/sns';
import {
  ReebeloB2bStoreT,
  ReebeloConditions,
  ReebeloGtinRegion,
  ReebeloLanguage,
  ReebeloSalesChannelT,
  ReebeloStoreT,
  ReebeloVendorsInvetoryOrigins,
} from '@lambda/reebelo';
import { Variant } from '@lambda/reebelo/src/tagHelpers';
import {
  VendorAgreementDoc,
  VendorAgreementRecord,
} from '@lambda/business/vendors/agreements/types';

export type DbObject<T extends Record<any, any>> = T & {
  pk: string;
  sk: string;
  'gsi-pk'?: string;
  'gsi-sk'?: string;
  'lsi-sk'?: string;
  'lsi-2-sk'?: string;
  'lsi-3-sk'?: string;
  ttl?: string;
};

export enum PKs {
  // AUTOMATIONS
  'OFFER' = 'OFFER',
  'OFFER_DETAILS' = 'OFFER_DETAILS',
  'SHOPPING_PROXY_CRAWL_SERVERS' = 'SHOPPING_PROXY_CRAWL_SERVERS',
  'REEEBLO_SHIPPING_CONFIG' = 'REEEBLO_SHIPPING_CONFIG',
  // PIM
  'PIMPRODUCT' = 'PIMPRODUCT',
  // VENDOR
  'VENDOR_DETAILS' = 'VENDOR_DETAILS',
  'VENDOR_API' = 'VENDOR_API',
  'VENDOR_BUYBACK_API' = 'VENDOR_BUYBACK_API',
  'VENDOR_INTEGRATION' = 'VENDOR_INTEGRATION',
  'VENDOR_B2B_DETAILS' = 'VENDOR_B2B_DETAILS',
  'VENDOR_SALES_DETAILS' = 'VENDOR_SALES_DETAILS',
  // B2B
  'B2B_ACCOUNT_REQUEST' = 'B2B_ACCOUNT_REQUEST',
  // STOREFRONT
  'ORDER_LOGIN' = 'ORDER_LOGIN',
  'ORDER_COUNT' = 'ORDER_COUNT',
  'COLLECTION_NODE' = 'COLLECTION_NODE',
  'PRODUCT_CATEGORY' = 'PRODUCT_CATEGORY',
  // Recently Viewed
  'RECENTLY_VIEWED_DETAILS' = 'RECENTLY_VIEWED_DETAILS',
  // BUYBACK
  'BUYBACK_PRODUCT' = 'PRODUCT',
  // CUSTOMER ACCEPTS MARKETING
  'CUSTOMER_ACCEPTS_MARKETING' = 'CUSTOMER_ACCEPTS_MARKETING',
  // WISHLIST
  'WISHLIST_DETAILS' = 'WISHLIST_DETAILS',
  'PAYMENT_GATEWAY_REFUND' = 'PAYMENT_GATEWAY_REFUND',
  // Vendor Agreements
  'VENDOR_AGREEMENT_DOC' = 'VENDOR_AGREEMENT_DOC',
  'VENDOR_AGREEMENT_RECORD' = 'VENDOR_AGREEMENT_RECORD',
}

export const MapPkToTableName: {
  [key in PKs]: 'database' | 'data-collections' | 'buyback-api-db-V3';
} = {
  [PKs.OFFER]: 'database',
  [PKs.OFFER_DETAILS]: 'database',
  [PKs.SHOPPING_PROXY_CRAWL_SERVERS]: 'database',
  [PKs.PIMPRODUCT]: 'database',
  [PKs.ORDER_LOGIN]: 'database',
  [PKs.ORDER_COUNT]: 'database',
  [PKs.VENDOR_DETAILS]: 'database',
  [PKs.VENDOR_API]: 'database',
  [PKs.VENDOR_INTEGRATION]: 'database',
  [PKs.VENDOR_B2B_DETAILS]: 'database',
  [PKs.B2B_ACCOUNT_REQUEST]: 'database',
  [PKs.COLLECTION_NODE]: 'database',
  [PKs.VENDOR_SALES_DETAILS]: 'data-collections',
  [PKs.PRODUCT_CATEGORY]: 'database',
  [PKs.RECENTLY_VIEWED_DETAILS]: 'database',
  [PKs.REEEBLO_SHIPPING_CONFIG]: 'database',
  [PKs.WISHLIST_DETAILS]: 'database',
  [PKs.CUSTOMER_ACCEPTS_MARKETING]: 'database',
  [PKs.BUYBACK_PRODUCT]: 'buyback-api-db-V3',
  [PKs.VENDOR_BUYBACK_API]: 'database',
  [PKs.PAYMENT_GATEWAY_REFUND]: 'database',
  [PKs.VENDOR_AGREEMENT_DOC]: 'database',
  [PKs.VENDOR_AGREEMENT_RECORD]: 'database',
};

export type MapPkToKeys = {
  // AUTOMATION
  [PKs.OFFER]: OfferT;
  [PKs.OFFER_DETAILS]: OfferDetailsT;
  [PKs.SHOPPING_PROXY_CRAWL_SERVERS]: ShoppingCrawlProxyServer;
  // PIM
  [PKs.PIMPRODUCT]: PimProductT;
  [PKs.ORDER_LOGIN]: OrderLoginT;
  [PKs.ORDER_COUNT]: OrderCountT;
  [PKs.VENDOR_DETAILS]: VendorDetails;
  [PKs.VENDOR_API]: VendorApi;
  [PKs.VENDOR_INTEGRATION]: VendorIntegration;
  [PKs.VENDOR_B2B_DETAILS]: VendorB2bDetails;
  [PKs.COLLECTION_NODE]: CollectionNodeT;
  [PKs.VENDOR_SALES_DETAILS]: VendorSalesDetailsT;
  [PKs.PRODUCT_CATEGORY]: ProductCategoryT;
  [PKs.RECENTLY_VIEWED_DETAILS]: RecentlyViewedT;
  [PKs.REEEBLO_SHIPPING_CONFIG]: ReebeloShipping;
  [PKs.BUYBACK_PRODUCT]: BuybackProduct;
  [PKs.WISHLIST_DETAILS]: WishlistDetails;
  [PKs.B2B_ACCOUNT_REQUEST]: B2bAccountRequest;
  [PKs.CUSTOMER_ACCEPTS_MARKETING]: CustomerAcceptsMarketing;
  [PKs.VENDOR_BUYBACK_API]: VendorApi;
  [PKs.PAYMENT_GATEWAY_REFUND]: PaymentGatewayRefund;
  // Vendor Agreements
  [PKs.VENDOR_AGREEMENT_DOC]: VendorAgreementDoc;
  [PKs.VENDOR_AGREEMENT_RECORD]: VendorAgreementRecord;
};
export type CollectionNodeT = {
  path: string;
  product: PimProductT | undefined;
};
type Rec<K extends keyof any, T> = Partial<Record<K, T>>;

export enum VendorDispatchType {
  'CROSS_BORDER' = 'CROSS_BORDER',
  'LOCAL' = 'LOCAL',
}

export type CheckoutIdToOrderIdMap = {
  checkoutId: string;
  orderId: string;
};

export type CustomerAcceptsMarketing = {
  customerId: string;
  buyerAcceptsMarketing: boolean;
};

export type DeliveryDaysEstimateRange = {
  minDays: number | null;
  maxDays: number | null;
};
export type DispatchDaysEstimateRange = {
  minDays: number | null;
  maxDays: number | null;
  cutOffTime?: string | null;
  expressMinDays?: number | null;
  expressMaxDays?: number | null;
  expressCutOffTime?: string | null;
};
export type ShippingOptionEstimateRange = {
  followingDay: boolean; // true: 1-2 days, false:2-5 days
  minDays: number | null;
  maxDays: number | null;
  nextDay: boolean;
};

export type StateEstimates<
  VendorState extends string = string,
  CustState extends string = string,
> = {
  local?: Record<VendorState, Record<CustState, DeliveryDaysEstimateRange>>;
  crossBorder?: Record<
    VendorState,
    Record<CustState, DeliveryDaysEstimateRange>
  >;
};
export type CategoryEstimates<Category extends string = string> = {
  local?: Record<Category, ShippingOptionEstimateRange>;
  crossBorder?: Record<Category, ShippingOptionEstimateRange>;
};

export type VendorDispatchEstimates<VendorName extends string = string> =
  Record<VendorName, DispatchDaysEstimateRange>;

export type ReebeloShippingByLocation = {
  store: 'reebelo-au' | 'reebelo-nz' | 'reebelo-ca';
  perCourier: Record<
    string,
    {
      key: string;
      name: string;
      estimates: StateEstimates;
    }
  >;
  perCategory?: CategoryEstimates;
  vendorDispatch: VendorDispatchEstimates; // Global
};

export type ReebeloShippingByCategory = {
  store: 'reebelo-us' | 'quista';
  perCategory: CategoryEstimates;
  vendorDispatch: VendorDispatchEstimates; // Global
};

export type ReebeloShipping =
  | ReebeloShippingByLocation
  | ReebeloShippingByCategory;

export const buybackProductTypeArray = [
  'phone',
  'laptop',
  'watch',
  'tablet',
] as const;

export type BuybackProductType = typeof buybackProductTypeArray[number];
export type BuybackProduct = {
  brand: string;
  category: BuybackProductType;
  condition: 'used' | 'new';
  cpu?: string;
  cpuDescription?: string;
  deviceSpecificIssues?: Record<string, boolean>;
  gpu?: string;
  gpuDescription?: string;
  image: string;
  model: string;
  productId: string;
  ram?: string;
  slug: string;
  storage: string;
  pimPSKU?: string;
};

export type WarrantyRate = {
  from: number;
  to: number;
  price: number;
};

export type SellerRatingPenalty = {
  from: number;
  to: number;
  penalty: number;
};

export enum B2BRequestAccountStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
export enum B2BRequestAccountType {
  SELLER = 'seller',
  BUYER = 'buyer',
}

export type B2bAccountRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  companyWebsite: string;
  country: string;
  revenue: string;
  type: B2BRequestAccountType;
  comment: string;
  status: B2BRequestAccountStatus;
};

export type VendorB2bDetails = {
  store: ReebeloB2bStoreT;
  vendor: string;
  displayName: string;
  invoicePrefix: string;
  emails?: Array<string>;
  company: {
    name: string;
    registrationNumber: string;
    /* in KR only */
    representative?: string;
    /* in KR only, registration number to sell online */
    salesNumber?: string;
    country: string;
    address2?: string;
    address1: string;
  };
};
export enum CommissionPayoutT {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  DAILY = 'daily',
}

export enum FinanceTransactionConditionsT {
  NEW = 'new',
  PREMIUM = 'premium',
  REFURBISHED = 'refurbished',
}
export type FinanceTrasactionCommissionCategoryRate = Record<
  FinanceTransactionConditionsT,
  number
>;

export const FinanceTransactionConditionMap: Record<
  ReebeloConditions,
  FinanceTransactionConditionsT
> = {
  [ReebeloConditions.Acceptable]: FinanceTransactionConditionsT.REFURBISHED,
  [ReebeloConditions.Excellent]: FinanceTransactionConditionsT.REFURBISHED,
  [ReebeloConditions.Good]: FinanceTransactionConditionsT.REFURBISHED,
  [ReebeloConditions.Pristine]: FinanceTransactionConditionsT.REFURBISHED,
  [ReebeloConditions.Premium]: FinanceTransactionConditionsT.PREMIUM,
  [ReebeloConditions.BrandNew]: FinanceTransactionConditionsT.NEW,
};

export type VendorDetailsSalesChannelCommissionT<
  Category extends string = string,
> = {
  new: number;
  premium: number;
  refurbished: number;
  expressShipping: number;
  ratesByCategory?: Record<Category, FinanceTrasactionCommissionCategoryRate>;
  ratesByCategoryPk?: Record<string, FinanceTrasactionCommissionCategoryRate>;

  reebeloOptedIn?: boolean;
  reebeloOptedInLastUpdateAt?: string;
  reebeloOptedInLastUpdateBy?: string;

  vendorOptedIn?: boolean;
  vendorOptedInLastUpdateAt?: string;
  vendorOptedInLastUpdateBy?: string;
};

export type VendorDetailsCommissionT = {
  reebeloCareNew: number;
  reebeloCareRef: number;
  logisticsFee: number;
  gstWithheldEndDate?: string;
  rollingReverseFee: number;
  cancellationFee: number;
  subscriptionFee: number;
  subscriptionFeeStartDate: string;
  payoutType: CommissionPayoutT;
  payoutRate?: number; // only for daily payout commission
  payoutTypeUpdatedAt?: string;
  gstHstNumber?: string;
  qstNumber?: string;
};

export enum VendorLabelFeeCharge {
  API = 'api',
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export type VendorDetails<Category extends string = string> = {
  store: ReebeloStoreT;
  vendor: string;
  displayName: string;
  invoicePrefix: string;
  emails?: Array<string>;
  isInactive?: boolean;
  company: {
    name: string;
    registrationNumber: string;
    /* in KR only */
    representative?: string;
    /* in KR only, registration number to sell online */
    salesNumber?: string;
    country: string;
    address2?: string;
    address1: string;
    reebeloCareB2B: boolean;
    usingSafeware?: boolean;
    enableGMS?: boolean;
    disableGST?: boolean;
  };
  dispatch: {
    minDays: number;
    maxDays: number;
    cutOffTime?: string; // in 24h format, example: 14:30
    expressMinDays?: number;
    expressMaxDays?: number;
    expressCutOffTime?: string; // in 24h format, example: 15:00
    type: VendorDispatchType;
    inventoryOrigin?: ReebeloVendorsInvetoryOrigins;
    postalCode?: string; // Only for vendors selling in AU Store
    state?: string; // Only for vendors selling in AU Store
    provider?: string; // Only for vendors selling in AU Store
    deliveryEstimatesByCategory?: Record<Category, ShippingOptionEstimateRange>; // Only for vendors selling in the US Store
    deliveryEstimatesByCategoryPk?: Record<string, ShippingOptionEstimateRange>; // For the new category tree, we use categoryPks
  };
  commissionBySalesChannel: Record<
    ReebeloSalesChannelT,
    VendorDetailsSalesChannelCommissionT
  >;
  commission: VendorDetailsCommissionT;
  safewareConfig?: {
    coverageType?: string;
    ratesByCategory?: Record<
      Category,
      Record<string, Array<WarrantyRate> | null>
    >;
  };
  safewareCoverage?: Record<
    '9_months' | '11_months',
    Record<Category, Record<'rates', Array<WarrantyRate> | null>> | null
  >;
  buybox?: {
    scoreModifier: number;
    baseVendorScore: number;
    vendorScore: number;
    sellerRating?: {
      nps?: {
        minimumVolume: number;
        ranges: Array<SellerRatingPenalty>;
      };
      oos?: {
        minimumVolume: number;
        ranges: Array<SellerRatingPenalty>;
      };
      dispatch?: {
        minimumVolume: number;
        ranges: Array<SellerRatingPenalty>;
      };
      delivery?: {
        minimumVolume: number;
        ranges: Array<SellerRatingPenalty>;
      };
      quality?: {
        minimumVolume: number;
        ranges: Array<SellerRatingPenalty>;
      };
    };
  };
  returns?: {
    refundLimit?: number;
    refundCurrentLimit?: number;
    refundLimitBreached?: boolean;
    secondaryRefundLimitBreached?: boolean;
    zendeskVendorFieldTag: string;
    tag: string; // DEPRECATED - this is ZD's identifier
    labelDisplayName?: string;
    labelFeeCharge?: {
      type: VendorLabelFeeCharge;
      value: number;
    };
    emails: Array<string>;
    primaryAddress: {
      address1: string;
      address2?: string;
      postcode: string;
      state: string;
      country: string;
      city: string; // or suburb
      phoneNumber?: string;
    };
    secondaryCategories: Array<string>; // secondary categories will use secondary address for returns
    secondaryAddress?: {
      address1: string;
      address2?: string;
      postcode: string;
      state: string;
      country: string;
      city: string; // or suburb
      phoneNumber?: string;
    };
  };
  // Exposed to vendors
  preferences?: {
    upPricerOptOut: boolean;
  };
};
export type VendorApi = {
  apiKey: string;
  vendor: string;
  feed?: {
    pausePrice?: boolean;
    pauseStock?: boolean;
  };
};
export type VendorIntegration = {
  vendor: string;
  shipStation?: {
    credentials: {
      username?: string;
      password?: string;
      appKey?: string;
      appSecret?: string;
    };
  };
  shippingEasy?: {
    apiKey: string;
    apiSecret: string;
    storeApiKey: string;
  };
  channelEngine?: {
    url: string;
    apiKey: string;
    baseProductFeedUrl: string;
  };
  shipWorks?: {
    apiKey: string;
    webhook: {
      endpoint: string;
      apiKey: string;
      hmacSecret: string;
    };
  };
  shipRush?: {
    postInUrl: string;
  };
  wholecell?: Record<string, never>;
  webhook?: {
    endpoint: string;
    headers?: Record<string, string>;
  };
  finale?: {
    account: string;
    username: string;
    password: string;
    feed?: {
      minimumStock?: number;
    };
  };
  sellerCloud?: {
    username: string;
    feed?: {
      pausePrice?: boolean;
      pauseStock?: boolean;
    };
    password: string;
  };
  linnworks?: {
    appKey: string;
  };
  channelAdvisor?: {
    appKey: string;
    sellerId: string;
    feed?: {
      pauseStock?: boolean;
      pausePrice?: boolean;
      pauseStatus?: boolean;
    };
  };
  shopify?: {
    account: string;
    password: string;
    feed?: {
      pause?: boolean;
      pauseStock?: boolean;
      pausePrice?: boolean;
      concurrency?: number;
      filterByTag?: string;
      discount?: number;
      location?: string;
    };
    order?: {
      pause?: boolean;
      writeOrders?: boolean;
    };
  };
  quipt?: {
    account: string;
    orderPrefix: string;
  };
  skuVault?: {
    tenantToken: string;
    userToken: string;
    feed?: {
      minimumStock?: number;
      kitSkus?: boolean;
    };
  };
  wooCommerce?: {
    store: string;
    appKey: string;
    appSecret: string;
    feed?: {
      pause?: boolean;
      pauseStock?: boolean;
      pausePrice?: boolean;
      customPriceField?: string;
      customMinPriceField?: string;
      discount?: number;
    };
    order?: {
      trackingCompanyField?: string;
      trackingNumberField?: string;
    };
  };
  neto?: {
    store: string;
    username: string;
    apiKey: string;
    feed?: {
      pause?: boolean;
      pauseStock?: boolean;
      pausePrice?: boolean;
      discount?: number;
      concurrency?: number;
    };
    order?: {
      userGroup?: string;
      shippingMethod?: string;
      shippingCost?: string;
      paymentMethod?: string;
      paymentMethodId?: string;
    };
  };
  magento?: {
    url: string;
    consumerKey: string;
    consumerSecret: string;
    accessToken: string;
    tokenSecret: string;
    feed?: {
      discount?: number;
    };
  };
  bigCommerce?: {
    storeHash: string;
    accessToken: string;
    feed?: {
      pausePrice?: boolean;
      discount?: number;
    };
  };
  sellbrite?: {
    accountToken: string;
    secretKey: string;
    feed?: {
      pausePrice?: boolean;
      discount?: number;
    };
  };
  sellerActive?: {
    accountId: string;
    password: string;
  };
  solidCommerce?: {
    accountToken: string;
    salesChannelCode: string;
    productSalesChannelCode?: string;
  };
  veeqo?: {
    apiKey: string;
    channelId: number;
    deliveryMethodId: number;
    warehouseIds?: string[];
    priorityWarehouseId?: string;
    feed?: {
      pausePrice?: boolean;
    };
  };
  odoo?: {
    baseUrl: string;
    db: string;
    username: string;
    password: string;
    port?: number;
  };
  listingMirror?: {
    username: string;
    token: string;
  };
  csvFeed?: {
    url: string;
    headers?: Record<string, string>;
  };
};
export type OrderLoginT = {
  store: ReebeloStoreT;
  orderNumber: number;
  email: string;
  deflatedOrder: Buffer;
};
export type OrderCountT = {
  store: ReebeloStoreT;
  vendor: string;
  count: number;
};
export const SimSlotSkus: Record<SimSlot, string> = {
  DS: 'Dual Sim',
  SS: 'Single Sim',
  ES: 'Dual eSim',
  SSES: 'Single Sim + eSim',
};
export const ConnectivitieSkus: Record<Connectivities, string> = {
  C: 'Cellular',
  W: 'WiFi',
  CW: 'Cellular + WiFi',
  '4G': '4G',
  '5G': '5G',
  G: 'GPS',
  CG: 'Cellular + GPS',
  B: 'Bluetooth',
  BL: 'Bluetooth + LTE',
};
export enum Connectivities {
  'WIFI' = 'W',
  'CELL' = 'C',
  'CELL_WIFI' = 'CW',
  '4G' = '4G',
  '5G' = '5G',
  'GPS' = 'G',
  'CELL_GPS' = 'CG',
  'BLUETOOTH' = 'B',
  'BLUETOOH_LTE' = 'BL',
}
export enum SimSlot {
  'DUAL_SIM' = 'DS',
  'SINGLE_SIM' = 'SS',
  'E_SIM' = 'ES',
  'SINGLE_SIM_E_SIM' = 'SSES',
}
export const ConnectivitiesSkusReverse: Record<string, Connectivities> = {
  Cellular: Connectivities.CELL,
  WiFi: Connectivities.WIFI,
  'Cellular + WiFi': Connectivities.CELL_WIFI,
  '4G': Connectivities['4G'],
  '5G': Connectivities['5G'],
  GPS: Connectivities.GPS,
  'Cellular + GPS': Connectivities.CELL_GPS,
  Bluetooth: Connectivities.BLUETOOTH,
  'Bluetooth + LTE': Connectivities.BLUETOOH_LTE,
};
export const SimSlotSkusReverse: Record<string, SimSlot> = {
  'Dual Sim': SimSlot.DUAL_SIM,
  'Single Sim': SimSlot.SINGLE_SIM,
  'Dual eSim': SimSlot.E_SIM,
  'Single Sim + eSim': SimSlot.SINGLE_SIM_E_SIM,
};
export enum CellularStatus {
  'LOCKED' = 'Locked',
  'UNLOCKED' = 'Unlocked',
  'PREPAID' = 'Prepaid',
}
export enum CellularNetwork {
  'GSM' = 'GSM',
  'CDMA' = 'CDMA',
}
export enum NetworkProvider {
  'AT&T' = 'AT&T',
  'VERIZON' = 'Verizon',
  'SPRINT' = 'Sprint',
  'T-MOBILE' = 'T-Mobile',
}
export enum BuyBackAvailability {
  NotAvailable = 'NotAvailable',
  Available = 'Available',
}

export enum BatteryHealth {
  New = 'New',
  Used = 'Used',
}

export type GtinStructure = {
  color?: string;
  storage?: string;
  gtinLists: { active: Array<string>; blacklist: Array<string> };
  gtin: string | null;
  cellularStatus?: string;
  cellularNetwork?: string;
  networkProvider?: string;
  region: ReebeloGtinRegion;
  bestGtin?: string;
  backupGtins?: string[];
  blacklistGtins?: string[];
};
type LocaleAttributes =
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'faq'
  | 'specifications'
  | 'keyFeatures'
  | 'brand'
  | 'category'
  | 'colors'
  | 'watchBandColors'
  | 'watchCaseColors';

export type KeyFeatureT = {
  rank: number;
  icon: string;
  title: string;
  content: string;
};

export enum ProductStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
}

export type PimProductT<
  Colors extends string = string,
  Rams extends string = string,
  Cpus extends string = string,
  Gpus extends string = string,
  WatchFaceSizes extends string = string,
  WatchCaseColors extends string = string,
  WatchCaseMaterials extends string = string,
  WatchBandTypes extends string = string,
  WatchBandColors extends string = string,
  watchBandSizes extends string = string,
  WatchBandMaterials extends string = string,
  ScreenSizes extends string = string,
  CellularStatuses extends string = string,
  CellularNetworks extends string = string,
  NetworkProviders extends string = string,
  BatteryHealthConditions extends string = string,
  Charger extends string = string,
  Version extends string = string,
  Language extends string = string,
  Touchbar extends string = string,
> = {
  /** Smartphones */
  category: string;
  /** New Category Pks */
  categoryPks: string[];
  /** Smartphones */
  subCategories: string[];
  /** iPhone 11 Pro Max */
  name: string;
  /** ['128GB', '256GB'] */
  storages: string[];
  /** [ 1.1GHz Intel Core i3,.... ]* */
  cpus: Cpus[];
  gpus: Gpus[];
  watchFaceSizes: WatchFaceSizes[];
  watchCaseColors: Record<WatchCaseColors, string>;
  watchCaseColorsInHex: Record<WatchCaseColors, string>;
  watchCaseMaterials: WatchCaseMaterials[];
  watchBandTypes: WatchBandTypes[];
  watchBandColors: Record<WatchBandColors, string>;
  watchBandColorsInHex: Record<WatchBandColors, string>;
  watchBandSizes: watchBandSizes[];
  watchBandMaterials: WatchBandMaterials[];
  screenSizes: ScreenSizes[];
  cellularStatuses: CellularStatuses[];
  cellularNetworks: CellularNetworks[];
  networkProviders: NetworkProviders[];
  batteryHealth: BatteryHealthConditions[];
  /** ['blue', 'yellow'] */
  colors: Record<Colors, string>;
  colorsInHex: Record<Colors, string>;
  colorsMaterial: Record<Colors, string>;

  /** { blue: ['https://...']} */
  images: Rec<Colors, string[]>;
  connectivities: Connectivities[];
  simSlot: SimSlot[];
  rams: Rams[];
  /** Apple */
  brand: string;
  regionalDescription?: Record<ReebeloStoreT, string>;
  regionalFaq?: Record<ReebeloStoreT, string>;
  description: string;
  shortDescription: string;
  faq: string;
  specifications: {
    key: string;
    value: string;
  }[];
  keyFeatures: KeyFeatureT[];
  /** P-IPHONE11 */
  psku: string;
  suggestedProducts: Array<{ psku: string }>;
  gtins: Array<GtinStructure>;
  comparePrice: Array<{ store: ReebeloStoreT; storage: string; price: number }>;
  collectionImageUrl?: string;
  adCollectionImageUrl?: string;
  buyBackAvailability?: BuyBackAvailability;
  buyBackAvailabilityUS?: BuyBackAvailability;
  locale: Rec<ReebeloLanguage, Pick<PimProductT, LocaleAttributes>>;
  collections: string[];
  releaseDate?: string;
  device_psku?: string;
  chargers: Charger[];
  versions: Version[];
  languages: Language[];
  touchbars: Touchbar[];
  status?: ProductStatus;
  variants?: Array<{
    name: string; // name of the attribute
    fieldName: string; // field name of the attribute values in the PIM
  }>;
  rVariants?: Array<{
    variant: string;
    values: string[];
  }>;
};

export type OfferT = {
  sku: string;
  rsku?: string;
  title: string;
  category: string;
  score: number;
  productId: string;
  variantId: string;
  vendorPrice: number;
  vendorMinPrice?: number;
  vendorTargetPrice?: number;
  totalPrice: number;
  handle: string;
  store: ReebeloStoreT;
  stock: number;
  vendor: string;
  variant: Variant;
  productType: string;
  brand: string;
  isBest: boolean;
  isDeal?: boolean;
  isLocal?: boolean;
  isBlacklisted?: boolean;
  isActive?: boolean;
  imageUrl: string;
  gtin: string | null;
  description: string;
  shortDescription: string;
  faq: string;
  subsidies: Array<string>;
};

export type OfferDetailsT = {
  vendor: string;
  sku: string;
  productId: string;
  price: number;
  stock: number;
  title: string;
};

export type VendorSalesDetailsT = {
  store: string;
  vendor: string;
  psku: string;
  rsku?: string;
  storage?: string;
  color?: string;
  condition?: string;
  cellularStatus?: string;
  networkProvider?: string;
  soldLast60Days?: number;
  soldLast60DaysByVendor?: number;
  gmvLast60Days?: number;
  gmvLast60DaysByVendor?: number;
  last30daysAveragePrice?: number;
  last30daysBenchmarkPrice?: number;
  totalStock?: number;
  soldLast30Days?: number;
  soldLast30DaysByVendor?: number;
  soldLast30DaysByVendorChangePercentage?: number;
  soldLast30DaysChangePercentage?: number;
  last30DaysAveragePriceChangePercentage?: number;
  last30DaysBenchmarkPriceChangePercentage?: number;

  sellThruCurrent?: number;
  sellThruBest?: number;

  soldLast30DaysMaxRate?: number;
  soldPre30DaysMaxRate?: number;
  soldLast30DaysCurrentBuyboxRate?: number;
  soldPre30DaysCurrentBuyboxRate?: number;

  gmvLast30Days?: number;
  gmvLast30DaysByVendor?: number;
  gmvLast30DaysChangePercentage?: number;
  gmvLast30DaysByVendorChangePercentage?: number;
  // Used for competitor offers
  bestPriceVendor?: string;
  buyboxCompetitorGapLastUpdated?: string;
  buyboxCompetitorGapPercentage?: number;
  lowestMarketPrice?: number;
  averageMarketPrice?: number;
};

export type WishlistPriceAlert = {
  price: number;
  timestamp: Date;
};

export type WishlistDetails = {
  // Required
  email: string;
  store: ReebeloStoreT;
  image: string;
  url: string;
  urlOrigin: string;
  urlPathname: string;
  name: string;
  price: number;
  reviews: { count: number; stars: number };
  timestamp: number;
  deletedAt: boolean;
  // Optional
  batteryHealth?: string;
  brand?: string;
  category?: string;
  cellularNetwork?: string;
  cellularStatus?: string;
  color?: string;
  condition?: string;
  connectivity?: string;
  cpu?: string;
  minPrice?: string;
  networkProvider?: string;
  psku?: string;
  ram?: string;
  screenSize?: string;
  simSlot?: string;
  storage?: string;
  watchBandColor?: string;
  watchBandMaterial?: string;
  watchBandSize?: string;
  watchBandType?: string;
  watchCaseColor?: string;
  watchCaseMaterial?: string;
  watchFaceSize?: string;
  touchbar?: string;
  version?: string;
  language?: string;
  charger?: string;
  sk?: string;
  id?: string;
  title?: string;
  sku?: string;
  vendor?: string;
  priceAlerts?: WishlistPriceAlert[];
};

export type ShoppingCrawlProxyServer = {
  region: ReebeloGtinRegion;
  hostname: string;
  portNumber: string;
  updatedAt: string;
};

export type ProductCategoryT = {
  store: ReebeloStoreT;
  category: string;
  isEligibleForWarranty: boolean;
  selectedProducts?: Array<{ category: string; brand?: string | null }>;
};

export type RecentlyViewedProductT = {
  name: string;
  image: string;
  type: 'product' | 'collection';
  handle: string;
  sku: string;
  tags: string[];
  addedAt: string;
  price: number;
  comparePrice?: number;
  summary: {
    count: number;
    stars: number;
    countAbove3andAHalf: number;
    starPercentages: Record<string, number>;
  };
  isCheapest?: boolean;
  vendor: string;
};

export type RecentlyViewedT = {
  email: string;
  store: ReebeloStoreT;
  data: RecentlyViewedProductT[];
};

export enum PaymentGatewayRefundOptions {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
}
export enum PaymentGatewayRefundStatus {
  PENDING = 'pending',
  PROCESSED = 'processed',
  FAILURE = 'failure',
}

export type PaymentGatewayRefundRequestT = {
  reason: string;
  amount: number;
  requestId: string;
  paymentGatewayTransactionId: string;
  paymentGateway: PaymentGatewayRefundOptions;
  store: ReebeloStoreT;
};
export type PaymentGatewayRefund = {
  requestId: string;
  status: PaymentGatewayRefundStatus;
  paymentGateway: PaymentGatewayRefundOptions;
  expiration?: string;
  retryExpiration?: string;
  retryCount?: number;
  ttl?: number;
  payload: PaymentGatewayRefundRequestT;
};

export type DynamoEventMessage<T extends Record<any, any>> = {
  keys: { pk: string; sk: string };
} & (
  | { oldObject: DbObject<T>; newObject: DbObject<T> }
  | { oldObject: undefined; newObject: DbObject<T> }
  | { oldObject: DbObject<T>; newObject: undefined }
);
export type TopicDynamoStream<
  T extends
    | PimProductT
    | OfferT
    | VendorDetails
    | CollectionNodeT
    | VendorSalesDetailsT
    | PaymentGatewayRefund
    | B2bAccountRequest =
    | OfferT
    | PimProductT
    | CollectionNodeT
    | VendorSalesDetailsT
    | PaymentGatewayRefund
    | B2bAccountRequest,
> = Sns<DynamoEventMessage<T>, { pk: string; sk: string }>;
