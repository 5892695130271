import { useState } from 'react';
import Image from 'next/future/image';
import cn from 'classnames';

import { priceFormatSuperscript } from '@/lib/price';
import { priceFormater } from '@/settings/index';
import infoIconGray from '@/public/icons/product/infoIconGray.svg';
import settings from '@/settings';
import { calculateSavings } from '@/lib/offer';

const Disclaimer = () => (
  <div className="absolute right-0 top-2 z-40 mt-2 w-[300px] rounded-lg border text-gray-700 shadow-md xs:top-[full] xs:w-[395px]">
    <div className="absolute -top-1 right-5 -z-10 h-3 w-3 rotate-45 border bg-white shadow-md"></div>
    <div className="rounded-lg bg-white px-3 py-2 text-center text-xs leading-tight xs:px-5 xs:py-4 xs:text-sm">
      This strikethrough comparison price shows how much you would pay for a new
      device, based on an average of retailer prices. The product compared may
      vary in its version, condition, warranty period, or other minor variations
      such as packaging.
    </div>
  </div>
);

const MainPriceInfo = ({
  comparePrice,
  actualPrice,
  isOldPdp = false,
  isTradeInEligible = false,
}: {
  comparePrice: number | undefined;
  actualPrice: number;
  isOldPdp?: boolean;
  isTradeInEligible?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isApacStore = ['reebelo-au', 'reebelo-nz', 'quista'].includes(
    settings.store,
  );

  const percentageSaving = calculateSavings(actualPrice, comparePrice);

  return (
    <div>
      {comparePrice != null && percentageSaving > 3 && (
        <div
          className={cn('mb-1 flex', {
            'flex-col items-end': isOldPdp,
            'items-center justify-end': !isOldPdp,
          })}
        >
          <div
            className={cn('text-xs text-slate-gray-300', {
              'cursor-pointer': isApacStore,
            })}
            onMouseEnter={() => isApacStore && setIsOpen(true)}
            onMouseLeave={() => isApacStore && setIsOpen(false)}
          >
            new{' '}
            <span className="line-through">
              {priceFormater.long(comparePrice)}
            </span>
            {isApacStore && (
              <span className="ml-1 inline-block h-[12px] w-[12px]  pt-[1.5px]">
                <Image
                  src={infoIconGray}
                  alt={infoIconGray}
                  className="w-full object-cover"
                />
              </span>
            )}
          </div>

          {isApacStore && isOpen && (
            <div className="relative">
              <Disclaimer />
            </div>
          )}

          <div
            className={cn('', {
              'mt-1 rounded-md bg-red': isOldPdp,
              'absolute right-[-39px] top-6 ml-2 hidden w-[40px] rounded-r-full bg-link-red-700 pr-2 leading-3 lg:block':
                !isOldPdp,
            })}
          >
            <span className="flex items-center justify-center p-1 px-1.5 text-center text-xxs font-bold text-white lg:p-1.5">
              {percentageSaving}% OFF
            </span>
          </div>
        </div>
      )}
      <div className="flex items-center justify-end">
        <h2
          className="block text-xl font-bold leading-tight text-gray-700"
          id="e2e-product-price"
        >
          {priceFormatSuperscript(priceFormater.long(actualPrice))}
        </h2>
      </div>
      {isTradeInEligible && (
        <div className="flex items-center justify-end text-xs">
          before trade-in
        </div>
      )}
    </div>
  );
};

export default MainPriceInfo;
