export function priceFormatSuperscript(price: string) {
  const decimalPart = price.split('.').pop() ?? '00';

  // to consider some store like KR currency don't have decimal
  return price.includes('.') ? (
    <>
      {price.split('.')[0]}
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <sup style={{ top: '-0.4em' }}>.{decimalPart}</sup>
    </>
  ) : (
    price
  );
}
