export default async function fetchDealViewsCount(
  isDeal: boolean,
  sku: string,
  endDate: number,
) {
  if (isDeal) {
    try {
      const response = await fetch(
        `/api/products/product-deal-view?sku=${sku}&endDate=${endDate}`,
      );

      if (!response.ok) throw new Error('Failed to fetch data');

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  return null;
}
