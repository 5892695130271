import { useState } from 'react';
import { priceFormater } from '@/settings';
import { priceFormatSuperscript } from '@/lib/price';
import i18n from '@/settings/i18n';

const t = i18n.init();

export default function ComparePriceInformation(props: {
  comparePrice: number | undefined;
  className?: string;
  actualPrice: number | undefined;
  noPopup?: boolean;
  noFormat?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { actualPrice, comparePrice, className, noPopup, noFormat } = props;

  if (actualPrice == null) return null;
  if (comparePrice == null || Number.isNaN(comparePrice)) return null;
  if (comparePrice <= actualPrice) return null;
  const price = noFormat
    ? priceFormater.long(comparePrice)
    : priceFormatSuperscript(priceFormater.long(comparePrice));

  return (
    <span
      className={`text-slate-gray-300 line-through ${
        className ?? ''
      } relative cursor-pointer`}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      {price}
      {isOpen && !noPopup && (
        <div className="absolute top-[65%] right-0 z-40 mt-2 w-[300px] rounded-md text-gray-700 shadow-md xs:top-[full] xs:w-[395px]">
          <div className="absolute -top-1 right-8 -z-10 h-3 w-3 rotate-45 bg-white shadow-md"></div>
          <div className="bg-white px-3 py-2 text-center text-xs leading-tight xs:px-5 xs:py-4 xs:text-sm">{t`This strikethrough comparison price shows how much you would pay for a
          new device, based on an average of retailer prices. The product compared
          may vary in its version, condition, warranty period, or other minor
          variations such as packaging.`}</div>
        </div>
      )}
    </span>
  );
}
