import { Dialog } from '@headlessui/react';
import cn from 'classnames';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import { findSubsidyTag } from '@lambda/reebelo/src/tagHelpers';
import { useState } from 'react';
import Loading from '@lambda/ui/src/Loading';
import { VARIANTS } from '@/lib/collections/catalogue/helpers';
import PurchaseLinks from '@/components/products/PurchaseLinks';
import useVendorOrderCount from '@/lib/use-vendor-order-count';
import useComparePrices from '@/lib/use-pim-compare-price';
import ComparePriceInformationNew from '@/components/products/ComparePriceInformationNew';
import { priceFormatSuperscript } from '@/lib/price';
import { priceFormater } from '@/settings/index';
import { formatDeliveryDateRange } from '@/lib/use-delivery-date';
import { TrustpilotStar } from '@/components/products/TrustpilotWidget';
import useVendorDetails, { useVendorDispatch } from '@/lib/use-vendor-details';
import { DeliveryDateResponse } from '@/api/products/delivery-date';
import { ExpressShippingOfferResponse } from '@/api/express-shipping/offer';
import { ShippingData } from './NewProductPage/types';
import useVendorReviews from '@/lib/use-vendor-reviews';
import { VendorReviewsT } from '@/settings/types';
import { generateSummaryData } from '../reviews/ProductReviews';
import { ProductSource } from '@/lib/analytics/types';

export type OfferWithDeliveryAndShippingInfo = SearchOffers & {
  deliveryDate: DeliveryDateResponse | null;
  expressShippingOffer?: ExpressShippingOfferResponse | null;
};

const PriceInformation = (props: {
  offerPrice: number;
  comparePrice?: number;
}) => {
  const { offerPrice, comparePrice } = props;

  return (
    <>
      <div className="inline pr-2 text-sm font-bold text-gray-700 lg:block">
        {priceFormatSuperscript(priceFormater.long(offerPrice))}
      </div>
      <ComparePriceInformationNew
        actualPrice={offerPrice}
        comparePrice={comparePrice}
        className="mt-1 text-sm xs:mt-0"
        noPopup={true}
      />
    </>
  );
};

const ShippingSelectionRadio = (props: {
  date: string;
  price: string;
  id: string;
  isExpress?: boolean;
  onShippingTypeChanged: (data: ShippingData) => void;
  expressShippingOffer?: ExpressShippingOfferResponse | null;
  deliveryDate?: DeliveryDateResponse | null;
}) => {
  const {
    date,
    price,
    id,
    isExpress,
    onShippingTypeChanged,
    expressShippingOffer,
    deliveryDate,
  } = props;

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <label className="custom-radio">
      <input
        type="radio"
        name={id}
        defaultChecked={!isExpress}
        onChange={() => {
          if (!isExpress || expressShippingOffer == null) {
            onShippingTypeChanged({
              type: 'standard',
              value: 0,
              cutoffTime: deliveryDate?.standard.cutoffTime || '',
              cutoffTimezone: deliveryDate?.standard.cutoffTimezone || '',
            });

            return;
          }

          onShippingTypeChanged({
            type: 'express',
            value: Number(expressShippingOffer?.price),
            variantId: expressShippingOffer
              ? expressShippingOffer?.variantId
              : 0,
            minDeliveryDate: deliveryDate?.express.minDeliveryDate || '',
            maxDeliveryDate: deliveryDate?.express.maxDeliveryDate || '',
            dispatch: expressShippingOffer.dispatch,
            cutoffTime: deliveryDate?.express.cutoffTime || '',
            cutoffTimezone: deliveryDate?.express.cutoffTimezone || '',
          });
        }}
      />
      <div className="inline lg:hidden">
        <span
          className={cn({ 'font-bold uppercase text-[#0DA864]': !isExpress })}
        >
          {price}
        </span>{' '}
        {isExpress ? `Express delivery by` : 'Standard delivery by'}
      </div>
      <div className="ml-1 inline font-bold lg:ml-0 lg:block">{date}</div>
      <div className="hidden lg:block">{price}</div>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <span className="checkmark"></span>
    </label>
  );
};

const tdClass =
  'xs:px-3 py-1 px-2 xs:py-2 lg:px-4 lg:py-5 align-top first:w-full lg:first:w-[150px] lg:overflow-hidden';
const lastColumnClass = `min-w-[200px]`;
const thClass = `text-sm ${tdClass} text-[#626565]`;
const trClass =
  'bg-white border border-gray-200 flex lg:table-row flex-wrap [&>*]:w-full lg:[&>*]:w-auto [&>*:nth-child(5)]:hidden lg:[&>*:nth-child(5)]:table-cell mb-3 xs:mb-4 lg:mb-0 w-full lg:rounded-none rounded-md lg:py-0 py-2 xs:py-3 lg:px-0 xs:px-3 px-1 overflow-hidden';

function OfferLine(props: {
  offer: OfferWithDeliveryAndShippingInfo;
  className?: string;
  isOtherOffer: boolean;
  categoryPk: string;
}) {
  const { offer, isOtherOffer, categoryPk } = props;

  const orderCount = useVendorOrderCount(offer.vendor);
  const dispatch = useVendorDispatch(offer.vendor);
  const comparePrice = useComparePrices({
    psku: offer.psku,
    storage: offer.storage,
    tags: offer.tags,
    actualPrice: offer.price,
    fallbackPrice: offer.comparePrice,
  });
  const { deliveryDate } = offer;
  const [shippingData, setShippingData] = useState<ShippingData>({
    type: 'standard',
    value: 0,
    cutoffTime: deliveryDate?.standard.cutoffTime || '',
    cutoffTimezone: deliveryDate?.standard.cutoffTimezone || '',
  });

  const standardDeliveryDateFormatted = formatDeliveryDateRange(
    deliveryDate?.standard,
    'short',
  );
  const expressDeliveryDateFormatted = formatDeliveryDateRange(
    deliveryDate?.express,
    'short',
  );
  const expressShippingOffer = offer?.expressShippingOffer;
  const shouldShowExpressShippingOption =
    expressDeliveryDateFormatted !== '' && expressShippingOffer != null;

  const handleShippingTypeChanged = (data: ShippingData) => {
    setShippingData(data);
  };
  const vendorReviews: VendorReviewsT = useVendorReviews(props.offer.vendor);
  const vendorSummary = generateSummaryData(vendorReviews.summary);

  const vendorInfo = useVendorDetails(props.offer.vendor);

  return (
    <tr className={cn(trClass, { 'lg:[&>*]:pt-7': !isOtherOffer })}>
      <td
        className={cn(tdClass, {
          'lg:pt-auto relative pt-5 xs:pt-7': !isOtherOffer,
        })}
      >
        <>
          {!isOtherOffer && (
            <>
              <div className="absolute -left-1 -top-2 flex h-[22px] w-[90px] items-center rounded-tl-md bg-[#39D892] pl-[12px] pr-3 text-left text-xs font-bold text-white xs:-left-3 xs:-top-3 xs:h-[28px] xs:w-[110px] xs:text-sm lg:left-0 lg:top-0 lg:h-[22px] lg:rounded-none lg:text-xs">
                Best Offer
              </div>
              {/* eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values */}
              <div className="absolute -top-[8px] left-[82px] h-[18px] w-[30px] rotate-[25deg] bg-white xs:left-[92px] lg:-top-[3px] lg:left-[107px] lg:h-[20px]"></div>
              {/* eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values */}
              <div className="absolute -top-[6px] left-[82px] h-[18px] w-[30px] -rotate-[25deg] bg-white xs:left-[92px] lg:left-[107px] lg:top-[3px] lg:h-[20px]"></div>
            </>
          )}
          <PriceInformation
            offerPrice={offer.price}
            comparePrice={comparePrice}
          />
        </>
      </td>
      <td
        className={cn(
          'lg:pt-auto order-4 border-t border-gray-200 pt-3 xs:pt-4 lg:order-none lg:border-t-0',
          tdClass,
        )}
      >
        <div className="flex flex-wrap justify-between text-sm lg:block">
          <div className="w-full pr-2 font-normal text-gray-700 xxs:w-auto xs:pr-0 lg:font-bold">
            <div className="inline">
              <span className="mr-1 inline lg:mr-0 lg:hidden">Sold by</span>
              {vendorInfo?.displayName}
            </div>
            <div className="ml-2 inline">
              <span>{vendorSummary.stars}/5</span>
              <TrustpilotStar className="mb-2 ml-1 inline h-4 w-4 lg:mb-1" />
            </div>
          </div>
          <div className="w-full text-left text-gray-700 xxs:w-auto xxs:text-right lg:text-left">
            {orderCount?.toLocaleString('en-US')} Orders
          </div>
        </div>
      </td>
      <td
        className={cn(tdClass, {
          'lg:pb-auto pb-3 xs:pb-5': !shouldShowExpressShippingOption,
        })}
      >
        {standardDeliveryDateFormatted !== '' ? (
          <ShippingSelectionRadio
            date={standardDeliveryDateFormatted}
            deliveryDate={deliveryDate}
            price="Free"
            id={offer.key}
            onShippingTypeChanged={handleShippingTypeChanged}
          />
        ) : (
          <div className="text-sm font-bold">
            {dispatch &&
              (dispatch?.max !== 1
                ? `${dispatch?.min} - ${dispatch?.max}`
                : `${dispatch?.min}`)}{' '}
            Biz Days
            <br className="hidden lg:inline-block"></br> Dispatch
          </div>
        )}
      </td>
      <td
        className={cn('lg:pb-auto pb-3 xs:pb-5', tdClass, {
          'lg:p-auto hidden h-0 !p-0 lg:block lg:h-auto':
            !shouldShowExpressShippingOption,
        })}
      >
        {shouldShowExpressShippingOption ? (
          <ShippingSelectionRadio
            date={expressDeliveryDateFormatted}
            price={priceFormater.short(expressShippingOffer.price)}
            id={offer.key}
            onShippingTypeChanged={handleShippingTypeChanged}
            expressShippingOffer={expressShippingOffer}
            deliveryDate={deliveryDate}
            isExpress={true}
          />
        ) : (
          <div className="px-2 py-1 align-top xs:px-3 xs:py-2 lg:px-4 lg:py-5">
            -
          </div>
        )}
      </td>
      <td className={tdClass}>
        <div className="text-sm font-bold">
          {dispatch.note.startsWith('Ships from')
            ? dispatch.note.replace('Ships from ', '')
            : 'Overseas'}
        </div>
      </td>
      <td
        className={cn(
          'lg:pt-auto order-5 pt-0 lg:order-none',
          tdClass,
          lastColumnClass,
        )}
      >
        <PurchaseLinks
          warranty={null}
          shippingData={shippingData}
          deliverBy={{
            min: deliveryDate?.standard.minDeliveryDate || '',
            max: deliveryDate?.standard.maxDeliveryDate || '',
          }}
          subsidyTag={findSubsidyTag(offer.tags)}
          cartButtonVariant="dark"
          offer={offer}
          category={offer.productType || offer.categories?.[0]}
          vendor={offer.vendor}
          variantId={offer.variantId}
          productId={offer.productId}
          comparePrice={comparePrice}
          className="grid w-full grid-cols-1"
          source="Other Offers"
          productSource={ProductSource.FASTER_DELIVERY}
          categoryPk={categoryPk}
        />
      </td>
    </tr>
  );
}

export default function OtherOffers(props: {
  productName: string;
  tags: string[];
  show: boolean;
  cheapest: OfferWithDeliveryAndShippingInfo | null;
  others: OfferWithDeliveryAndShippingInfo[];
  specs: Record<string, unknown>;
  onClose: () => void;
  categoryPk: string;
}) {
  const { cheapest, others, specs, categoryPk } = props;

  const getProductName = () => props.productName.replace(/Oppo/g, 'OPPO');

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-[50] overflow-hidden"
      onClose={props.onClose}
      open={props.show}
    >
      <div className="min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-gray-700/[.3]" />
        <div className="absolute right-0 inline-block h-full w-full overflow-auto bg-gray-300 text-left align-middle shadow-xl transition-all xl:w-10/12 2xl:w-9/12">
          <div className="sticky bg-white px-5 py-4 shadow-lg xs:py-5 sm:px-9">
            <Dialog.Title
              as="h3"
              className="text-xl font-bold leading-6 text-gray-700"
            >
              {getProductName()}
            </Dialog.Title>
            <p className="text-sm text-gray-700">
              {VARIANTS.map((v) => specs[v])
                .filter((v) => v)
                .join(' | ')
                .replace('Very Good', 'Good')}
            </p>
            <button
              onClick={() => props.onClose()}
              type="button"
              className="btn-close absolute right-6 top-[50%] -translate-y-1/2 opacity-100 md:h-3 md:w-3"
              aria-label="Close"
            ></button>
          </div>

          {cheapest == null ? (
            <Loading />
          ) : (
            <div className="mt-6 px-5 sm:px-9">
              <table className="w-full border-none border-gray-200 bg-gray-300 lg:border lg:bg-white">
                <thead className="hidden lg:contents">
                  <tr className={trClass}>
                    <th className={thClass}>Offer</th>
                    <th className={thClass}>Seller</th>
                    <th className={thClass}>Standard Shipping</th>
                    <th className={thClass}>Express Shipping</th>
                    <th className={thClass}>Origin</th>
                    <th className={thClass}></th>
                  </tr>
                </thead>
                <tbody className="flex w-full flex-wrap lg:table-row-group">
                  <OfferLine
                    offer={cheapest}
                    isOtherOffer={false}
                    key={cheapest.productId}
                    categoryPk={categoryPk}
                  />
                  {others.length ? (
                    others
                      .filter(
                        // we hide if a vendor has the same vendor twice
                        (o) =>
                          o.vendor !== cheapest?.vendor ||
                          o.price !== cheapest?.price,
                      )
                      .map((o) => (
                        <OfferLine
                          key={o.productId}
                          offer={o}
                          isOtherOffer={true}
                          className="border-t border-gray-200 bg-transparent !px-0 !py-4 lg:!px-0 lg:!py-3"
                          categoryPk={categoryPk}
                        />
                      ))
                  ) : (
                    <tr>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
