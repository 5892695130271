import useSWR from 'swr';
import type { OfferUppricerMeta } from '@/lib/backend/uppricer';

const isReadyToFetch = ({ vendor, sku }: { vendor?: string; sku?: string }) =>
  vendor != null && sku != null;

export default function useOfferUppricerMeta({
  vendor,
  sku,
}: {
  vendor?: string;
  sku?: string;
}) {
  const response = useSWR<OfferUppricerMeta>(() =>
    isReadyToFetch({ vendor, sku })
      ? `/api/products/offer-uppricer?sku=${encodeURIComponent(
          sku as string,
        )}&vendor=${encodeURIComponent(vendor as string)}`
      : null,
  );

  return response?.data;
}
