import { useState } from 'react';
import Image from 'next/future/image';
import cn from 'classnames';

import { priceFormatSuperscript } from '@/lib/price';
import { priceFormater } from '@/settings/index';
import infoIconGray from '@/public/icons/product/infoIconGray.svg';
import settings from '@/settings';
import { calculateSavings } from '@/lib/offer';

const Disclaimer = () => (
  <div className="absolute -left-28 bottom-5 z-40 mt-2 hidden w-[300px] rounded-lg border text-gray-700 shadow-md xxs:block xs:top-[full] xs:w-[395px]">
    <div className="absolute -bottom-1 left-16 -z-10 h-3 w-3 rotate-45 border bg-white shadow-md"></div>
    <div className="rounded-lg bg-white px-3 py-2 text-center text-xs leading-tight xs:px-5 xs:py-4 xs:text-sm">
      This strikethrough comparison price shows how much you would pay for a new
      device, based on an average of retailer prices. The product compared may
      vary in its version, condition, warranty period, or other minor variations
      such as packaging.
    </div>
  </div>
);

const SecondaryPriceInfo = ({
  actualPrice,
  comparePrice,
  isTradeInEligible = false,
}: {
  actualPrice: number;
  comparePrice: number | undefined;
  isTradeInEligible?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isApacStore = ['reebelo-au', 'reebelo-nz', 'quista'].includes(
    settings.store,
  );

  const percentageSaving = calculateSavings(actualPrice, comparePrice);

  return (
    <div className="shrink-0 lg:hidden">
      {isTradeInEligible && <div className="text-xs">before trade-in</div>}
      <h2
        className="block text-xl font-bold leading-tight text-gray-700"
        id="e2e-product-price"
      >
        {priceFormatSuperscript(priceFormater.long(actualPrice))}
      </h2>
      {comparePrice != null && percentageSaving > 3 && (
        <div className="flex flex-row items-center">
          <div className="mr-2 flex shrink-0 items-center justify-center rounded-md bg-red p-1.5 text-center text-xxs font-bold leading-3 text-white">
            {percentageSaving}% OFF
          </div>
          <div
            className={cn('hidden text-xs text-slate-gray-300 xxs:block', {
              'cursor-pointer': isApacStore,
            })}
            onMouseEnter={() => isApacStore && setIsOpen(true)}
            onMouseLeave={() => isApacStore && setIsOpen(false)}
          >
            new{' '}
            <span className="line-through">
              {priceFormater.long(comparePrice)}
            </span>
            {isApacStore && (
              <span className="ml-1 inline-block h-[12px] w-[12px]  pt-[1.5px]">
                <Image
                  src={infoIconGray}
                  alt={infoIconGray}
                  className="w-full object-cover"
                />
              </span>
            )}
          </div>

          {isApacStore && isOpen && (
            <div className="relative">
              <Disclaimer />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SecondaryPriceInfo;
