import {
  ReebeloBatteryHealth,
  ReebeloConditions,
  ReebeloStoreT,
} from '@lambda/reebelo';
import {
  BABY_CATEGORIES,
  isBabyCategory,
  REEBELO_TO_BABY_CONDITION_MAPPINGS,
} from '@lambda/reebelo/src/commonHelpers';
import settings from '@/settings';
import atnt from '@/public/icons/collection/at-t.svg';
import tMobile from '@/public/icons/collection/t-mobile.svg';
import verizon from '@/public/icons/collection/verizon.svg';
import unlocked from '@/public/icons/collection/unlocked.svg';
import { ReviewSummaryT } from '@/settings/types';
import { NON_TECH_CATEGORIES } from '@/settings/configs';

type ImgSrcType = {
  src: string;
  height: number;
  width: number;
};

type SvcImgType = {
  image: ImgSrcType;
  size: string;
};

const getBatteryHealthLabel = (batteryHealth: string) => {
  if (batteryHealth === ReebeloBatteryHealth.BH_100)
    return `New ${batteryHealth}%`;

  return `Min. ${batteryHealth}%`;
};

export const shouldShowPromoMessage = (store: ReebeloStoreT, price: number) =>
  ((store === 'reebelo-ca' || store === 'reebelo-us') && price >= 35) ||
  (store === 'reebelo-au' && price <= 2000) ||
  (store === 'quista' && price >= 30);

export const getCarrierImage: (serviceProvider: string) => SvcImgType = (
  serviceProvider,
) => {
  const svcProviderImgMap: Record<string, SvcImgType> = {
    'AT&T': {
      image: atnt,
      size: 'h-[18px]',
    },
    Verizon: {
      image: verizon,
      size: 'h-[15px]',
    },
    'T-Mobile': {
      image: tMobile,
      size: 'h-[12px]',
    },
    Unlocked: {
      image: unlocked,
      size: 'h-[12px] md:h-[14px]',
    },
  };

  return svcProviderImgMap[serviceProvider];
};

export const getContrastYIQ = (hexcolor: string) => {
  const r = parseInt(hexcolor?.substr(0, 2), 16);
  const g = parseInt(hexcolor?.substr(2, 2), 16);
  const b = parseInt(hexcolor?.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? 'black' : 'white';
};

export const mostPopularProducts = [
  'iPhone 11',
  'iPhone 12',
  'iPhone 11 Pro',
  'iPhone X',
  'iPhone 12 Pro Max',
];

export const generateVendorStars = (vendorSummary: ReviewSummaryT) =>
  vendorSummary.stars > 4.5 ? vendorSummary.stars : 4.5;

// Certified Quality & Packagin Helpers (Shared between Old & New Product Pages)
export const shouldShowWhatIsInTheBox = (
  category: string,
  tags?: Array<string>,
) => {
  const isBundle = tags?.some((tag) => tag.match(/Category_.*Bundle$/) != null);

  if (settings.store === 'quista') {
    return (
      [
        'Smartphones',
        'Tablets',
        'Laptops',
        'Smartwatches',
        'Desktop Computer',
        'Computer Monitors',
        'Monitors',
        'Gaming Monitors',
      ].includes(category) && !isBundle
    );
  }

  return ['Smartphones', 'Tablets', 'Laptops'].includes(category) && !isBundle;
};

export const includesPowerPlug = (category: string) => {
  if (['quista'].includes(settings.store))
    return !['Smartphones', 'Tablets', 'Smartwatches'].includes(category);

  if (['reebelo-ca', 'reebelo-us'].includes(settings.store))
    return !['Smartphones', 'Tablets'].includes(category);

  return !['Smartphones'].includes(category);
};

export const isTech = (category: string) =>
  !NON_TECH_CATEGORIES.includes(category);

export const includesChargingCable = (category: string) =>
  ![
    'Laptops',
    'Desktop Computer',
    'Computer Monitors',
    'Monitors',
    'Gaming Monitors',
  ].includes(category);

export const generateFilterLabel = (
  title: string,
  categories: string[],
  k: string | ReebeloConditions,
) => {
  if (title === 'condition' && categories.length > 0) {
    const isBaby = categories.every((c) => BABY_CATEGORIES.includes(c));

    if (isBaby)
      return REEBELO_TO_BABY_CONDITION_MAPPINGS[k as ReebeloConditions] ?? k;
  }

  if (title === 'batteryHealth') return getBatteryHealthLabel(k);

  return k;
};

export const streamLineCondition = (
  category: string | undefined,
  k: ReebeloConditions | string,
) => {
  if (category && isBabyCategory(category)) {
    return (
      REEBELO_TO_BABY_CONDITION_MAPPINGS[k as ReebeloConditions] ??
      k.replace('Very Good', 'Good')
    );
  }

  return k.replace('Very Good', 'Good');
};

export const checkIsTradeInEligible = (): boolean =>
  ['reebelo-dev', 'reebelo-us'].includes(settings.store);
