import { useState } from 'react';
import Image from 'next/future/image';
import { PimProductT } from '@lambda/apis/src/dynamoDb/types';
import chevronRight from '@/public/icons/product/chevronRight.svg';
import SpecificationsModal from './SpecificationsModal';
import {
  ReplacedProduct,
  SelectionT,
} from '@/lib/collections/catalogue/helpers';
import { ShippingData } from './types';
import { ProductDetails } from '@/components/products/PurchaseLinks';

export default function SpecificationsNew(props: {
  specifications: { key: string; value: string }[];
  productName: string;
  comparePrice?: number;
  price?: number;
  specs: Record<string, unknown>;
  variantId: number;
  productId: number;
  warranty: number | null;
  shippingData: ShippingData;
  deliverBy?: { min: string; max: string };
  subsidyTag?: string;
  isOutOfStock?: boolean;
  upgradation?: string;
  vendor: string;
  offer?: ReplacedProduct;
  productDetails?: ProductDetails;
  category?: string;
  key?: string;
  selection?: SelectionT;
  product?: PimProductT;
}) {
  const [show, setShow] = useState(false);

  const container = 'rounded-t-md bg-white sm:block cursor-pointer';
  const flex =
    'rounded-t-md justify-between flex border border-gray-200 bg-white py-5 px-7';

  return (
    <div className={container}>
      <div className={flex} onClick={() => setShow(!show)}>
        <p className="mt-2 justify-start text-xs font-bold text-gray-700 xxs:mt-0 sm:text-sm">
          Specifications
        </p>
        <div className="relative flex items-center justify-end px-3">
          <div
            className="mt-2 h-[9px] w-[6px] xxs:mt-0"
            onClick={() => setShow(!show)}
          >
            <Image
              src={chevronRight}
              alt="expand"
              width={6}
              height={9}
              className="h-full w-full"
            />
          </div>
        </div>
      </div>
      <SpecificationsModal
        show={show}
        onClose={() => setShow(!show)}
        specifications={props.specifications}
        productName={props.productName}
        offer={props.offer}
        warranty={props.warranty}
        shippingData={props.shippingData}
        deliverBy={props.deliverBy}
        category={props.category}
        comparePrice={props.comparePrice}
        specs={props.specs}
        price={props.price}
        vendor={props.vendor}
        subsidyTag={props.subsidyTag}
        productId={props.productId}
        variantId={props.variantId}
        upgradation={props.upgradation}
        productDetails={props.productDetails}
        isOutOfStock={props.isOutOfStock}
      />
    </div>
  );
}
