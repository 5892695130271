import useSWR from 'swr';

export default function useVendorProductSoldCount(vendor?: string) {
  const response = useSWR<number>(
    vendor
      ? `/api/products/vendor-product-sold?vendor=${encodeURIComponent(vendor)}`
      : null,
  );

  return response.data;
}
