import { useRouter } from 'next/router';
import { useEffect } from 'react';
import cn from 'classnames';
import settings from '@/settings';

const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID!;
declare global {
  interface Window {
    Klarna: {
      OnsiteMessaging: {
        refresh: () => void;
      };
    };
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'klarna-placement': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'square-placement': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const AFFIRM_CONFIG = {
  public_api_key: settings.affirmPublicApiKey || '',
  script:
    process.env.NODE_ENV === 'production'
      ? 'https://cdn1.affirm.com/js/v2/affirm.js'
      : 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
};

const AffirmPromoMessage = ({ price }: { price: number }) => {
  const router = useRouter();

  // Handle Script Injection to support URL Change
  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `(function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,${JSON.stringify(
      AFFIRM_CONFIG,
    )},"affirm","checkout","ui","script","ready");`;
    script.async = true;
    script.id = 'affirm';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [router.asPath]);

  return (
    <div
      id="affirm-promo"
      data-learnmore-show={false}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="affirm-as-low-as mt-1 text-[11px] leading-[0.75] xs:text-xs sm:mt-0"
      data-page-type="product"
      data-amount={(price * 100).toString()}
    />
  );
};

const KlarnaPromoMessage = ({ price, id }: { price: number; id: string }) => {
  // Handle Script Injection to support URL Change
  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.setAttribute('data-environment', 'production');
    script.src = 'https://js.klarna.com/web-sdk/v1/klarna.js';
    script.setAttribute(
      'data-client-id',
      'ed19835d-475b-5588-a6e9-51f5c03bf503',
    );

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const router = useRouter();

  useEffect(() => {
    const refreshKlarnaOnPathChange = () => {
      if (window.Klarna && window.Klarna.OnsiteMessaging)
        window.Klarna.OnsiteMessaging.refresh();
    };

    const handleRouteChange = () => {
      refreshKlarnaOnPathChange();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.asPath]);

  return (
    <div className="lg:-mt-1">
      <klarna-placement
        id={id}
        data-key="credit-promotion-badge"
        data-locale="en-US"
        data-purchase-amount={price * 100}
      />
    </div>
  );
};

const AfterPayPromoMessage = ({
  price,
  id,
  category,
  sku,
}: {
  price: number;
  id: string;
  category: string;
  sku: string;
}) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.src = 'https://js.squarecdn.com/square-marketplace.js';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex items-center justify-center lg:justify-end lg:text-right">
      <square-placement
        id={id}
        data-mpid="4e265d02-f695-4975-a444-b6aacf3a7488"
        data-placement-id="22081ae3-2fef-435e-a6d2-09e030c289bd"
        data-page-type="product"
        data-amount={price}
        data-currency="AUD"
        data-consumer-locale="en_AU"
        data-item-skus={sku}
        data-item-categories={category}
        data-is-eligible="true"
        // eslint-disable-next-line react/forbid-dom-props
        style={{ marginBottom: '0px' }}
      />
    </div>
  );
};

const PayPalPromoMessage = ({
  price,
  type,
}: {
  price: number;
  type: 'new-pdp' | 'old-pdp';
}) => (
  <div
    data-pp-message
    data-pp-placement="product"
    data-pp-amount={price}
    className={cn({
      'mx-auto mb-5 mt-5 ml-2 flex justify-center sm:justify-start md:mb-0':
        type === 'old-pdp',
      'my-5 flex justify-center px-5 sm:px-8 lg:my-0 lg:justify-end lg:px-0':
        type === 'new-pdp',
    })}
    data-pp-style-logo-type="alternative"
    data-pp-style-text-size={11}
  />
);

const addPayPalScript = () => {
  if (document.getElementById('paypal-promo-message'))
    return 'paypal-promo-message'; // Avoid re-adding script

  const script = document.createElement('script');

  script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=AUD&components=messages`;
  script.async = true;
  script.id = 'paypal-promo-message';

  document.body.appendChild(script);

  return () => {
    if (document.getElementById('paypal-promo-message'))
      document.body.removeChild(script);
  };
};

export default function PromoMessages({
  price,
  type,
  category,
  sku,
}: {
  price: number;
  type: 'new-pdp' | 'old-pdp';
  category?: string;
  sku?: string;
}) {
  const { store } = settings;

  const isDevStore = store === 'reebelo-dev';
  const isUSStore = store === 'reebelo-us';
  const isCAStore = store === 'reebelo-ca';
  const isAUStore = store === 'reebelo-au';

  const shouldShowAffirmPromoMessage = isDevStore && price >= 50;
  const shouldShowKlarnaPromoMessage = (isUSStore || isCAStore) && price >= 35;
  const shouldShowAfterPayPromoMessage = isAUStore && price <= 2000;
  const showPayPalPromoMessage =
    shouldShowAfterPayPromoMessage && price <= 2000 && price >= 30;

  useEffect(() => {
    if (isAUStore) addPayPalScript();
  }, []);

  if (shouldShowAffirmPromoMessage) return <AffirmPromoMessage price={price} />;
  if (shouldShowKlarnaPromoMessage)
    return <KlarnaPromoMessage price={price} id={`${type}-klarna-placement`} />;

  if (showPayPalPromoMessage)
    return <PayPalPromoMessage price={price} type={type} />;

  if (shouldShowAfterPayPromoMessage) {
    return (
      <AfterPayPromoMessage
        id="afterpay-placement"
        price={price}
        category={category ?? ''}
        sku={sku ?? ''}
      />
    );
  }

  return null;
}
