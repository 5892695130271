import { Paginate } from './types/common';

/**
 * Escape string for GrqphQl query. See: https://shopify.dev/concepts/about-apis/search-syntax
 * @param str string to escape
 * @returns escaped string
 */
export function queryEscape(str: string) {
  if (str.includes('"') && str.includes(' ')) {
    throw Error(
      `String ${str} contains both space and ". Current shopify query won't work`,
    );
  }
  if (str.includes('"')) return str; // we do not do exact match otherwise " will break the query

  return `"${str}"`;
}

export function generateUrl(
  domain: string,
  handle: string,
  variantId?: number,
) {
  const url = `https://${domain}/products/${handle}`;

  if (variantId) return `${url}?variant=${variantId}`;

  return url;
}

export function gqlIdToId(): undefined;
export function gqlIdToId(gqlId: string): number;
export function gqlIdToId(gqlId?: string): number | undefined;

export function gqlIdToId(gqlId?: string) {
  if (!gqlId) return undefined;

  return Number(gqlId.replace(/\D/g, ''));
}

/**
 * Generate a Shopify query with the correct parsing and encoding.
 * @param params parameters to query
 * @returns a query as a string to send to Shopify GraphQl API.
 */
export function query(params: { sku?: string; vendor?: string; tag?: string }) {
  const queryItems = [];

  if (params.sku) {
    const escapedSku = queryEscape(params.sku);

    queryItems.push(`sku:${escapedSku}`);
  }
  if (params.vendor) queryItems.push(`vendor:"${params.vendor}"`);
  if (params.tag) queryItems.push(`tag:"${params.tag}"`);

  return queryItems.join(' AND ');
}

/**
 * Return the next cursor of a paginated request.
 * @param edges the edges of a paginated request
 * @returns the next cursor, undefined if there is no more edge.
 */
export function extractNextCursor(data: Paginate<any>) {
  return data.edges.length === 0 || data.pageInfo?.hasNextPage === false
    ? undefined
    : (data.edges[data.edges.length - 1].cursor as string | undefined);
}
