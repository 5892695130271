import useSWR from 'swr';
import md5 from 'crypto-js/md5';
import { VendorReviewsT } from '@/settings/types';

// Create a custom hook to fetch reviews data
function useVendorReviews(vendor: string) {
  const vendorHash = md5(vendor.toLowerCase());
  const {
    data: reviews = {
      summary: {
        count: 0,
        stars: 0,
        countAbove3andAHalf: 0,
        starPercentages: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0 },
      },
      reviews: [],
    },
  } = useSWR<VendorReviewsT>(
    `/api/collections/reviews?fileName=vendors_v2/${vendorHash}.json`,
  );

  return reviews;
}

export default useVendorReviews;
