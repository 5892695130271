/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/no-danger */
import { Product } from '@lambda/commons/apis/shopify/types/storefront';
import {
  ReebeloConditions,
  REEBELO_CURRENCY,
  REEBELO_DOMAINS,
} from '@lambda/reebelo';
import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import settings from '@/settings';

export default (props: {
  product: Product;
  price: number;
  isOrganicAdRedirect: boolean;
}) => {
  const { product, price } = props;
  const domain = REEBELO_DOMAINS[settings.store];
  const { condition, brand } = extractTags(product.tags);
  const image = product.images?.edges?.[0]?.node?.url ?? '';

  const firstVariant = product.variants.edges[0].node;
  const gtin = firstVariant.barcode;

  const data = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: product.title,
    url: `https://${domain}/products/${product.handle}`,
    image,
    description: product.description,
    brand: { '@type': 'Brand', name: brand },
    gtin,
    offers: [
      {
        '@type': 'Offer',
        itemCondition: `https://schema.org/${
          condition === ReebeloConditions.BrandNew || props.isOrganicAdRedirect
            ? 'NewCondition'
            : 'RefurbishedCondition'
        }`,
        availability:
          product.totalInventory < 0
            ? 'http://schema.org/OutOfStock'
            : 'http://schema.org/InStock',
        price: Number(price)?.toFixed(2),
        priceCurrency: REEBELO_CURRENCY[settings.store],
        gtin,
        url: `https://${domain}/products/${product.handle}`,
        seller: { '@type': 'Organization', name: product.vendor },
      },
    ],
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};
