import useSWR from 'swr';
import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import { round } from 'lodash-es';
import { ReebeloStoreT } from '@lambda/reebelo';
import { PimProductT } from '@lambda/apis/src/dynamoDb/types';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import settings from '../settings';

export function computeComparePrice({
  matchedComparePrice,
  tags,
  actualPrice,
}: {
  matchedComparePrice?: number;
  tags: string[];
  actualPrice: number;
}) {
  // only US store using MSRP
  if (settings.store !== 'reebelo-us') return matchedComparePrice;

  // find MSRP tag
  const { msrp } = extractTags(tags);

  if (msrp != null) {
    const save = round(((Number(msrp) - actualPrice) * 100) / Number(msrp));

    // save is less than 5% fallback to compare price
    if (save < 5) return matchedComparePrice;

    return Number(msrp);
  }

  return matchedComparePrice;
}

export function getComparePrice(
  pimProduct: PimProductT,
  searchOffer: SearchOffers,
): number | undefined {
  // This logic was copied from pages/api/topdeals/index.ts - tyler 2023-08-30
  // Find comparePrice from Catalogue (only works of Smartphone and tablets)
  const matchedComparePrice = pimProduct?.comparePrice?.find(
    (c) => c.storage === searchOffer.storage && c.store === searchOffer.store,
  )?.price;

  return (
    computeComparePrice({
      matchedComparePrice,
      tags: searchOffer.tags,
      actualPrice: searchOffer.price,
    }) || searchOffer.comparePrice
  );
}

export default function useComparePrices({
  psku,
  existingPimComparePrice,
  storage,
  tags,
  actualPrice,
  fallbackPrice,
}: {
  psku?: string;
  existingPimComparePrice?: {
    storage: string;
    price: number;
    store: ReebeloStoreT;
  }[];
  storage: string | undefined;
  tags: string[];
  actualPrice: number;
  fallbackPrice?: number;
}) {
  const { data: pimComparePrices } = useSWR<
    Array<{ storage: string; price: number; store: ReebeloStoreT }>
  >(() =>
    psku && existingPimComparePrice == null
      ? `/api/products/compare-price?psku=${psku}`
      : null,
  );

  const comparePrices =
    existingPimComparePrice == null
      ? pimComparePrices
      : existingPimComparePrice;

  // find matched compare price using storage
  const matchedComparePrice = comparePrices?.find(
    (c) => c.storage === storage && c.store === settings.store,
  )?.price;

  return (
    computeComparePrice({
      matchedComparePrice,
      tags,
      actualPrice,
    }) || fallbackPrice
  );
}
