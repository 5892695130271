import { ReplacedProduct } from '@/lib/collections/catalogue/helpers';

export const getBatteryHealthFromOfferTag = (
  offer: ReplacedProduct | null | undefined,
) => {
  if (!offer) return null;

  return offer?.tags
    .find((tag) => tag.startsWith('BatteryHealth_'))
    ?.replace('BatteryHealth_', '');
};

// commented out until categories are mapped and ready for release
export const BATTERY_HEALTH_ALLOWED_CATEGORIES: string[] = [
  'Smartphones',
  'Tablets',
  'Smartwatches',
  'Laptops',
  'E-scooters',
  'E-skateboards',
  'E-bikes',
  'Electric Bikes',
  'Hoverboards',
];
