import { Dispatch, SetStateAction } from 'react';
import Image from 'next/future/image';
import Modal from '../Modal';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const STEPS = [
  'Open the Settings app.',
  'Go to General > About.',
  "At the top of the list, you'll see Model Name",
  'Tip: You can also scroll down to check the capacity of your iPhone',
];

export default function FindModel({
  showModal,
  setShowModal,
}: Readonly<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}>) {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Find your iPhone model"
    >
      <div className="flex-1 overflow-auto p-4">
        <div className="rounded-lg bg-[#F5FFFF] p-4 shadow-sm sm:p-8">
          <div className="mx-auto w-1/2 shrink-0">
            <Image
              alt="Instructions"
              src={getEdgeUrl(
                'images/buyback/questionnaire/modal/find-my-iphone.png',
              )}
              height={500}
              width={500}
              quality={100}
              className="h-auto w-full object-contain"
            />
          </div>
        </div>
        <div className="mt-4">
          <h4 className="text-base font-bold">
            The simplest way to tell which iPhone you have is to open up the
            Settings app.
          </h4>
          <ul className="mt-2 list-inside list-decimal leading-normal">
            {STEPS.map((step) => (
              <li key={step}>{step}</li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
}
