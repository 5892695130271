import Image from 'next/future/image';
import { Fragment } from 'react';
import { getEdgeUrl } from '@/lib/getEdgeUrl';
import moneyIcon from '@/public/icons/buyback/faq/money.svg';
import truckIcon from '@/public/icons/buyback/faq/truck.svg';
import shieldIcon from '@/public/icons/buyback/faq/shield.svg';

const UPS = [
  { content: 'Highest Payout', icon: moneyIcon },
  { content: 'Free Shipping', icon: truckIcon },
  { content: 'Full Data Erasure', icon: shieldIcon },
];

const TradeInBanner = () => (
  <Fragment>
    <div className="relative flex items-center justify-center bg-teal-500 px-5 py-10 text-center">
      <div className="absolute left-0 bottom-0 h-[150px]">
        <Image
          src={getEdgeUrl('images/buyback/pdp/left-banner.png')}
          alt="Laptop"
          quality={100}
          height={200}
          width={100}
          loading="eager"
          className="h-full w-auto object-contain object-bottom"
        />
      </div>
      <div className="absolute right-0 top-0 h-full max-h-[200px]">
        <Image
          src={getEdgeUrl('images/buyback/pdp/right-banner.png')}
          alt="Laptop"
          quality={100}
          height={200}
          width={100}
          loading="eager"
          className="h-full w-auto object-contain"
        />
      </div>
      <div className="max-w-[70%]">
        <h4 className="text-2xl font-bold leading-tight">
          Trade-in your old device, and pay less for your new one.
        </h4>
        <p className="mt-5 text-sm">
          Answer a few quick questions about the device you'd like to trade-in
          and we'll offer you our highest payout.
        </p>
      </div>
    </div>
    <div className="flex items-center justify-around gap-5 bg-gray-200 p-5 text-xs">
      {UPS.map((ups) => (
        <div
          className="flex flex-col items-center gap-2 xxs:flex-row"
          key={ups.content}
        >
          <div className="h-5 w-5">
            <Image
              src={ups.icon}
              className="h-full w-full"
              height={20}
              width={20}
              alt={ups.content}
              loading="eager"
            />
          </div>
          <span className="text-center xxs:text-left">{ups.content}</span>
        </div>
      ))}
    </div>
  </Fragment>
);

export default TradeInBanner;
