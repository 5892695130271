import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import Image from 'next/future/image';
import settings, { priceFormater } from '@/settings';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import { X } from '@/public/icons/account/icons';
import TradeInLogo from './TradeInLogo';
import infoIconBlack from '@/public/icons/product/infoIconBlack.svg';

const TradeInSection = ({
  className,
  eligible,
  setShowModal,
}: {
  className?: string;
  eligible: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const router = useRouter();
  const isCartPage = router.pathname.includes('cart');

  const { quote, resetQuote, isLoading, summaryQuote, finalQuote } =
    useBuybackContext();

  if (
    ['reebelo-dev', 'reebelo-us'].includes(settings.store) &&
    !isLoading &&
    eligible
  ) {
    const isQuoteAccepted =
      quote?.isAccepted && finalQuote && finalQuote.paymentMethod;

    const content = (() => {
      if (isQuoteAccepted) {
        return {
          title: `You saved ${priceFormater.short(
            quote['basePrice-used'],
          )} today`,
          subtitle: `with your ${quote.model.split(' - ')[0]}`,
        };
      }

      return {
        title: 'Trade in your old tech today',
        subtitle: 'and save big on your new purchase!',
      };
    })();

    return (
      <div className={className}>
        <button
          onClick={() => isQuoteAccepted && setShowModal(true)}
          className={cn({
            'mb-1 flex text-xxs font-bold uppercase text-gray-700 lg:font-normal':
              !isCartPage,
            'mb-1 text-base font-bold': isCartPage,
            'flex items-center justify-center gap-2': isQuoteAccepted,
            'cursor-default': !isQuoteAccepted,
          })}
        >
          <h4 className="flex text-xxs font-bold uppercase text-gray-700 lg:font-normal">
            Trade-in
          </h4>
          {isQuoteAccepted && (
            <div className="-mt-0.5 h-3 w-3">
              <Image
                src={infoIconBlack}
                alt="Condition"
                width={12}
                height={12}
                className="h-full w-full object-contain"
              />
            </div>
          )}
        </button>

        <div
          className={cn(
            'relative flex w-full items-center gap-4 overflow-hidden rounded-md border p-4',
            {
              'text-sm': isCartPage,
              'text-xs': !isCartPage,
            },
          )}
          // eslint-disable-next-line react/forbid-dom-props
          style={{
            background: isCartPage
              ? 'linear-gradient(90deg, #ECFCF5 0%, rgba(255, 255, 255, 0.00) 100%)'
              : '',
          }}
        >
          <TradeInLogo />
          <div className="flex flex-1 flex-col flex-wrap justify-between gap-4 xs:flex-row xs:items-center">
            <div className="flex-1">
              <h3 className="font-bold">{content.title}</h3>
              <h4 className="text-xs">{content.subtitle}</h4>
            </div>
            <button
              onClick={() => {
                if (isQuoteAccepted) resetQuote();
                else setShowModal(true);
              }}
              type="button"
              className={cn(
                'shrink-0 rounded-md border border-gray-700 px-4 py-2 text-xs transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white',
                {
                  'font-bold': isCartPage,
                },
              )}
            >
              {isQuoteAccepted ? (
                <div className="flex items-center justify-center">
                  Remove my Trade-in <X className="ml-2 h-4 w-4" />
                </div>
              ) : (
                `${summaryQuote != null ? 'Continue' : 'Start'} trade-in`
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default TradeInSection;
