import useSWR from 'swr';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import type { DeliveryDateResponse } from '@/api/products/delivery-date';
import settings from '@/settings';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const isReadyToFetch = ({
  zipcode,
  state,
  category,
}: {
  zipcode?: string;
  state?: string;
  category?: string;
}) => {
  if (settings.store === 'reebelo-au')
    return !!zipcode && !!state && !!category;

  return category != null;
};

export default function useDeliveryDate({
  zipcode,
  state,
  category,
  vendor,
}: {
  zipcode?: string;
  state?: string;
  category?: string;
  vendor: string;
}) {
  const response = useSWR<DeliveryDateResponse>(() =>
    isReadyToFetch({ zipcode, state, category })
      ? `/api/products/delivery-date?zipcode=${zipcode}&state=${state}&category=${category}&vendor=${encodeURIComponent(
          vendor,
        )}`
      : null,
  );

  return response.data;
}

interface DeliveryDateRangeType {
  minDeliveryDate: string;
  maxDeliveryDate: string;
  cutoffTime?: string;
  cutoffTimezone?: string;
}

export function converDateTimeToLocal(date: string) {
  const storeDate = dayjs(dayjs(date)).format('YYYY-MM-DD HH:mm:ss');
  const storeMinDateUTC = dayjs
    .tz(storeDate, REEBELO_TZ_NAME[settings.store])
    .utc();
  const localDate = dayjs(storeMinDateUTC).local();

  return dayjs(localDate).tz(REEBELO_TZ_NAME[settings.store]);
}

const generateCommonFormat = (format: 'long' | 'short' | 'compact') => {
  switch (format) {
    case 'long':
      return 'Do MMMM';
    case 'compact':
      return 'MMM D';
    default:
      return 'MMMM D';
  }
};

export function formatDeliveryDateRange(
  deliveryDateRange: DeliveryDateRangeType | undefined,
  format: 'long' | 'short' | 'compact',
) {
  if (
    typeof deliveryDateRange === 'undefined' ||
    deliveryDateRange.minDeliveryDate === ''
  )
    return '';

  // format into user local date
  const minDate = converDateTimeToLocal(deliveryDateRange.minDeliveryDate);
  const maxDate = converDateTimeToLocal(deliveryDateRange.maxDeliveryDate);

  const commonFormat = generateCommonFormat(format);
  let minDeliveryDateFormat = 'Do';
  let maxDeliveryDateFormat = 'D';

  if (minDate.format('MMMM') !== maxDate.format('MMMM')) {
    // not same month
    minDeliveryDateFormat = commonFormat;
    maxDeliveryDateFormat = 'MMMM D';
  }

  if (dayjs(minDate).isSame(dayjs(maxDate))) {
    // same date
    return `${maxDate.format(commonFormat)}`;
  }

  if (format === 'long') {
    return `${minDate.format(minDeliveryDateFormat)} - ${maxDate.format(
      commonFormat,
    )}`;
  }

  return `${minDate.format(commonFormat)} - ${maxDate.format(
    maxDeliveryDateFormat,
  )}`;
}

export function formatDeliveryCutOffTime(
  deliveryDateRange: DeliveryDateRangeType | undefined,
) {
  if (
    typeof deliveryDateRange === 'undefined' ||
    deliveryDateRange.cutoffTime === ''
  )
    return '';

  const storeCutOffDate = dayjs()
    .tz(deliveryDateRange.cutoffTimezone)
    .format('YYYY-MM-DD');
  const storeCutOffDateTime = dayjs
    .tz(
      `${storeCutOffDate} ${deliveryDateRange.cutoffTime}:59`,
      REEBELO_TZ_NAME[settings.store],
    )
    .format('YYYY-MM-DD HH:mm:ss');
  const cutOffDateTimeAtUTC = dayjs
    .tz(storeCutOffDateTime, REEBELO_TZ_NAME[settings.store])
    .utc();

  const localTimeNow = dayjs().local();
  const localCutoffTime = dayjs(cutOffDateTimeAtUTC).local();
  const localDifference = localCutoffTime.diff(localTimeNow, 'minutes');

  // only show within 1 hour prior to its cut off time
  if (localDifference > 60 || localDifference < 0) return null;

  return localCutoffTime.format('h:mm A');
}
