import useSWR from 'swr';
import { REEBELO_COUNTRY } from '@lambda/reebelo';
import {
  VendorDetails,
  VendorDispatchType,
} from '@lambda/apis/src/dynamoDb/types';
import settings from '@/settings';

export default function useVendorDetails(vendor: string) {
  const response = useSWR<VendorDetails>(
    () =>
      vendor
        ? `/api/products/vendor-details?vendor=${encodeURIComponent(vendor)}`
        : null,
    {
      // No need to revalidate since it is cached for 1 day
      // https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return response.data;
}

export type DispatchType = {
  min: number;
  max: number;
  note: string;
  type: string;
};

export function useVendorDispatch(vendor: string): DispatchType {
  const details = useVendorDetails(vendor);
  const country = REEBELO_COUNTRY[settings.store];

  if (details?.dispatch == null) {
    return {
      min: 1,
      max: 4,
      note: `Ships from ${country}`,
      type: 'standard',
    };
  }
  const { minDays, maxDays, type } = details.dispatch;
  const isLocal = type === VendorDispatchType.LOCAL;

  return {
    min: minDays,
    max: maxDays,
    note: isLocal ? `Ships from ${country}` : 'Direct Import',
    type: maxDays === minDays && minDays === 1 ? 'express' : 'standard',
  };
}
