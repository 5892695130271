import Image from 'next/future/image';
import { VendorDetails } from '@lambda/apis/src/dynamoDb/types';
import cn from 'classnames';
import { useState } from 'react';
import PaymentIcons from './PaymentIcons';
import { TrustpilotStar } from '@/components/products/TrustpilotWidget';
import settings from '@/settings';
import droppedPin from '@/public/images/droppedPin.svg';
import VendorReviews from '../../reviews/VendorReviews';
import { DispatchType } from '@/lib/use-vendor-details';
import { ZipcodeStateData } from '@/lib/use-customer-zipcode';
import useVendorProductSoldCount from '@/lib/use-vendor-product-sold-count';
import { generateSummaryData } from '../../reviews/ProductReviews';
import { VendorReviewsT, ProductDetailsT } from '@/settings/types';
import useVendorReviews from '@/lib/use-vendor-reviews';
import { generateVendorStars } from '@/lib/collections/helpers';
import DeliveryByPopup from '@/components/commons/Navbar/AccessBar/DeliveryByPopup';

type VendorInfoSectionProps = {
  zipcodeStateData: ZipcodeStateData | null;
  setZipcodeStateData: (data: ZipcodeStateData) => void;
  dispatch: DispatchType;
  vendor: string;
  productDetails: ProductDetailsT;
  vendorInfo: VendorDetails | undefined;
};

const VendorInfoSection = (props: VendorInfoSectionProps) => {
  const [isDeliverByOpen, setIsDeliverByOpen] = useState(false);

  const {
    setZipcodeStateData,
    zipcodeStateData,
    dispatch,
    productDetails,
    vendor,
    vendorInfo,
  } = props;

  const [showVendorProfile, setShowVendorProfile] = useState(false);
  const vendorReviews: VendorReviewsT = useVendorReviews(vendor);

  const vendorSummary = generateSummaryData(vendorReviews.summary);
  const totalSales = useVendorProductSoldCount(vendor);

  const shouldShowVendorProfile =
    vendorReviews != null &&
    vendorReviews.summary.count > 3 &&
    vendorInfo != null &&
    totalSales != null &&
    totalSales > 5;

  const vendorStars = generateVendorStars(vendorSummary);

  return (
    <>
      <div className="rounded-md border border-gray-200 bg-white text-sm">
        <div className="grid grid-cols-[auto,70%] p-4 xxs:grid-cols-2">
          {['reebelo-au', 'reebelo-nz', 'reebelo-ca'].includes(
            settings.store,
          ) && (
            <>
              <div className="flex flex-row justify-start">Deliver to</div>
              <div className="flex flex-row justify-end">
                <div
                  className="flex cursor-pointer"
                  onClick={() => setIsDeliverByOpen(true)}
                >
                  <div className="flex items-center">
                    <div className="relative w-[20px] px-1">
                      <Image
                        src={droppedPin}
                        alt="Deliver to"
                        width={10}
                        height={12}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex-[0_0_auto] text-right underline">
                    {zipcodeStateData?.stateName || '-'}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="flex flex-row justify-start">Origin</div>
          <div className="flex flex-row justify-end">
            {dispatch.note.startsWith('Ships from')
              ? dispatch.note.replace('Ships from ', '')
              : vendorInfo?.company.country}
          </div>

          <div className="mt-4 flex flex-row justify-start pr-1 xxs:pr-0">
            Authenticated & sold by
          </div>
          {vendorInfo != null && (
            <div className="mt-4 flex  flex-row flex-wrap items-center justify-end">
              <button
                type="button"
                id="e2e-seller-sold-by"
                className={cn('text-right', {
                  underline: shouldShowVendorProfile,
                  'cursor-default': !shouldShowVendorProfile,
                })}
                onClick={() => setShowVendorProfile(true)}
              >
                {vendorInfo.displayName}
              </button>
              <div className="ml-1 flex items-center">
                <TrustpilotStar className="mb-1 h-3 w-3 lg:h-4 lg:w-4" />
                <span className="font-bold">{vendorStars}</span>
                /5
              </div>
            </div>
          )}
        </div>
        <div className="border-t border-gray-200 p-4">
          <div className="flex flex-col items-center">
            <PaymentIcons />
          </div>
        </div>
      </div>
      <DeliveryByPopup
        isOpen={isDeliverByOpen}
        onClose={() => setIsDeliverByOpen(false)}
        onStateNameChange={(data: ZipcodeStateData) => {
          setZipcodeStateData(data);
        }}
      />
      <VendorReviews
        productDetails={productDetails}
        setOpen={setShowVendorProfile}
        open={shouldShowVendorProfile && showVendorProfile}
        vendor={vendorInfo as VendorDetails}
        vendorReviews={vendorReviews}
        totalSales={totalSales ?? 0}
      />
    </>
  );
};

export default VendorInfoSection;
