import i18n from '@/settings/i18n';

const t = i18n.init();

export default function ProductSold(props: {
  totalProductSold: number | undefined;
}) {
  const { totalProductSold } = props;

  // we only showing sold count more than 100
  if (totalProductSold === undefined) return null;
  const totalSoldTxt =
    totalProductSold > 100
      ? totalProductSold.toLocaleString().toString()
      : '100+';

  return (
    <span className="mr-2 text-xs">{t`${{
      totalSoldTxt,
    }} Sold`}</span>
  );
}
