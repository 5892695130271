import useSWR from 'swr';

export default function useVendorOrderCount(vendor: string) {
  const response = useSWR<number>(
    () =>
      vendor
        ? `/api/products/order-count?vendor=${encodeURIComponent(vendor)}`
        : null,
    {
      // No need to revalidate since it is cached for 1 day
      // https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return response.data;
}
