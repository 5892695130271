/* eslint-disable @typescript-eslint/no-var-requires */
import auPopularCategories from './AU.json';
import nzPopularCategories from './NZ.json';
import usPopularCategories from './US.json';
import sgPopularCategories from './SG.json';
import caPopularCategories from './CA.json';
import { PopularCategory } from './types';

let popularCategories = auPopularCategories;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-au')
  popularCategories = auPopularCategories;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-nz')
  popularCategories = nzPopularCategories;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-dev')
  popularCategories = auPopularCategories;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-us')
  popularCategories = usPopularCategories;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-ca')
  popularCategories = caPopularCategories;

if (process.env.NEXT_PUBLIC_STORE === 'quista')
  popularCategories = sgPopularCategories;

export default popularCategories as PopularCategory[];
