import Image from 'next/future/image';
import limiteOfferSvg from '@/public/icons/reebelocare/limited-offer.svg';

const LimitedOffer = ({ top = -4.5, left = -6, height = 80 }) => (
  <div
    className="absolute z-20"
    // eslint-disable-next-line react/forbid-dom-props
    style={{
      top,
      left,
    }}
  >
    <div
      // eslint-disable-next-line react/forbid-dom-props
      style={{ height }}
    >
      <Image
        src={limiteOfferSvg}
        height={300}
        width={300}
        quality={100}
        alt="Limited Offer"
        className="h-full w-auto"
      />
    </div>
  </div>
);

export default LimitedOffer;
