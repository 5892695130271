import Image from 'next/future/image';
import { Dispatch, SetStateAction } from 'react';
import returnIcon from '@/public/icons/buyback/return-green.svg';
import chevronRightIcon from '@/public/icons/buyback/chevron-right-green.svg';
import settings, { priceFormater } from '@/settings';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';

const Content = ({
  label,
  isQuoteAccepted = false,
  resetQuote,
}: {
  label: string;
  isQuoteAccepted?: boolean;
  resetQuote?: () => void;
}) => (
  <div className="flex w-full items-center justify-center rounded-full bg-[#ECFEF6] py-3 px-3 text-xs text-[#116A43] lg:h-auto lg:w-fit lg:py-2">
    <div className="flex items-center gap-2">
      <div className="h-4 w-4">
        <Image
          src={returnIcon}
          alt="Trade-In"
          height={20}
          width={20}
          className="h-full w-full"
        />
      </div>
      {label}
      {isQuoteAccepted ? (
        <button
          onClick={() => resetQuote && resetQuote()}
          className="ml-2 font-bold underline"
        >
          Remove
        </button>
      ) : (
        <div className="ml-3 h-4 w-4">
          <Image
            src={chevronRightIcon}
            alt="Chevron Right"
            height={20}
            width={20}
            className="h-full w-full"
          />
        </div>
      )}
    </div>
  </div>
);

const CompactTradeIn = ({
  setShowTradeInModal,
  eligible,
}: {
  setShowTradeInModal: Dispatch<SetStateAction<boolean>>;
  eligible: boolean;
}) => {
  const { quote, resetQuote, isLoading, summaryQuote, finalQuote } =
    useBuybackContext();

  if (
    ['reebelo-dev', 'reebelo-us'].includes(settings.store) &&
    !isLoading &&
    eligible
  ) {
    const isQuoteAccepted =
      quote?.isAccepted && finalQuote && finalQuote.paymentMethod;

    const label = (() => {
      if (isQuoteAccepted)
        return `You saved ${priceFormater.short(quote['basePrice-used'])}`;
      if (summaryQuote) return 'Continue Trade-In';

      return 'Save with Trade-In';
    })();

    if (isQuoteAccepted)
      return <Content label={label} isQuoteAccepted resetQuote={resetQuote} />;

    return (
      <button
        onClick={() => setShowTradeInModal(true)}
        className="w-full lg:w-fit"
      >
        <Content label={label} />
      </button>
    );
  }

  return null;
};

export default CompactTradeIn;
