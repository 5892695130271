import cn from 'classnames';
import { useState } from 'react';
import Image from 'next/future/image';
import settings, { priceFormater } from '@/settings';
import infoIconGray from '@/public/icons/product/infoIconGray.svg';
import { calculateSavings } from '@/lib/offer';

const Disclaimer = ({
  isOpen,
  isApacStore,
}: {
  isOpen: boolean;
  isApacStore: boolean;
}) =>
  isOpen && isApacStore ? (
    <div className="absolute right-0 top-[65%] z-40 mt-2 w-[300px] rounded-md text-gray-700 shadow-md xs:top-[full] xs:w-[395px]">
      <div className="absolute -top-1 right-8 -z-10 h-3 w-3 rotate-45 bg-white shadow-md"></div>
      <div className="bg-white px-3 py-2 text-center text-xs leading-tight xs:px-5 xs:py-4 xs:text-sm">
        This strikethrough comparison price shows how much you would pay for a
        new device, based on an average of retailer prices. The product compared
        may vary in its version, condition, warranty period, or other minor
        variations such as packaging.
      </div>
    </div>
  ) : null;

export default function PriceInformation(props: {
  comparePrice: number | undefined;
  className?: string;
  savingsClass?: string;
  actualPrice: number | undefined;
  view: string;
  isTradeInEligible?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { actualPrice, comparePrice, view, isTradeInEligible = false } = props;

  if (actualPrice == null) return null;

  const percentageSaving = calculateSavings(actualPrice, comparePrice);

  const { store } = settings;
  const isApacStore =
    store === 'reebelo-au' || store === 'reebelo-nz' || store === 'quista';

  const classes: any = {
    stickyBar: {
      p: 'block text-right text-xl font-bold text-gray-700',
      pId: '',
      wasClass: 'mb-2 px-1 text-xs text-slate-gray-300',
      comparePriceClass:
        'text-slate-gray-300 line-through mb-2 text-right text-xs',
      flexClass: 'flex items-start justify-center gap-x-2',
      roundedClass: 'mb-2 rounded bg-red shrink-0',
      saveClass:
        'flex items-center justify-center p-1 text-center font-bold text-xxs text-white',
      container: 'mb-2',
    },
    desktop: {
      p: 'block mt-1 py-0 font-bold text-xl leading-tight text-gray-700',
      pId: 'e2e-product-price',
      wasClass: 'px-1 text-xs text-slate-gray-300 no-underline',
      comparePriceClass:
        'text-slate-gray-300 line-through text-xs relative cursor-pointer',
      flexClass: 'flex items-end flex-col -mt-2',
      roundedClass:
        'ml-2 bg-link-red-700 absolute -right-[44px] w-[45px] pr-2 top-6 rounded-r-full',
      saveClass:
        'flex items-center justify-center p-1.5 text-center font-bold text-xxs text-white leading-tight',
      container: 'm-0',
    },
    mobileTop: {
      p: 'block font-bold text-gray-700 xs:text-xl',
      pId: 'e2e-product-price-mobile',
      wasClass: 'px-1 text-xs text-slate-gray-300 mb-2',
      comparePriceClass:
        'text-xs text-slate-gray-300 line-through mb-2 text-right relative cursor-pointer',
      flexClass: 'flex items-end -mt-1.5 sm:flex-col',
      roundedClass:
        'ml-2 rounded bg-red -order-2 hidden sm:block sm:order-last',
      saveClass:
        'flex items-center justify-center p-1.5 text-center font-bold text-xxs text-white',
    },
    mobileBottom: {
      p: 'block text-base font-bold text-gray-700 xs:text-xl',
      pId: '',
      wasClass: 'pr-1 text-xs text-slate-gray-300',
      comparePriceClass: 'text-slate-gray-300 line-through text-xs',
      flexClass: 'flex items-end gap-x-1',
      roundedClass: 'rounded bg-red',
      saveClass:
        'flex items-center justify-center min-w-[55px] px-1.5 py-1 text-center font-bold text-xxs text-white ',
      container: 'order-last flex',
    },
  };

  const {
    p,
    pId,
    flexClass,
    wasClass,
    comparePriceClass,
    roundedClass,
    saveClass,
    container,
  } = classes[view];

  const showComparePrice = comparePrice != null && percentageSaving > 3;

  return (
    <>
      {isTradeInEligible && <div className="text-xs">before trade-in</div>}
      <p className={p} id={pId}>
        {priceFormater.long(actualPrice)}
      </p>
      {showComparePrice && (
        <div className={flexClass}>
          <div className={container}>
            <span className={wasClass}>new</span>
            <span
              className={cn(comparePriceClass, {
                '!no-underline': !showComparePrice,
              })}
              onMouseEnter={() => {
                setIsOpen(true);
              }}
              onMouseLeave={() => {
                setIsOpen(false);
              }}
            >
              <>
                {priceFormater.long(comparePrice)}
                {isApacStore && (
                  <span className="ml-1 inline-block h-[12px] w-[12px] pt-0.5">
                    <Image
                      src={infoIconGray}
                      alt={infoIconGray}
                      className="w-full object-cover"
                    />
                  </span>
                )}
                <Disclaimer isOpen={isOpen} isApacStore={isApacStore} />
              </>
            </span>
          </div>
          <div
            className={cn(props.savingsClass, {
              [`${roundedClass}`]: showComparePrice,
            })}
          >
            <span className={saveClass}>{percentageSaving}% OFF</span>
          </div>
        </div>
      )}
    </>
  );
}
