import Image from 'next/future/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Navigation } from 'swiper';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { debounce } from 'lodash-es';
import { useSwiperSlider } from '@/components/commons/hooks';

SwiperCore.use([Navigation]);

const SCROLL_HEIGHT = (54 + 12) * 4; // (Thumbnail Container Height + Gap height) * Number of Thumbnails we want to display

const Thumbnails = ({
  images,
  slideTo,
  selectedImage,
  defaultAltTag,
}: {
  images: Array<{ url: string }> | Array<string>;
  defaultAltTag?: string;
  slideTo: (i: number) => void;
  selectedImage: number;
}) => {
  const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [showBottomArrow, setShowBottomArrow] = useState(false);

  // Handle Arrows on Scroll
  const handleScroll = debounce((e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target as HTMLElement;

    if (scrollTop > 0) setShowUpArrow(true);
    else setShowUpArrow(false);

    if (scrollTop + clientHeight === scrollHeight) setShowBottomArrow(false);
    else setShowBottomArrow(true);
  }, 300);

  // Initialize Right Arrow
  useEffect(() => {
    if (containerRef.current != null) {
      if (
        containerRef.current.scrollHeight +
          containerRef.current.clientHeight ===
        containerRef.current.scrollHeight
      )
        setShowBottomArrow(false);
      else setShowBottomArrow(true);
    }
  }, [containerRef]);

  const shouldDisplayNavigation = images.length > 5;

  return (
    <>
      <div className="hidden flex-col items-center lg:flex">
        {shouldDisplayNavigation && (
          <button
            className="flex h-12 w-12 items-center justify-center"
            disabled={!showUpArrow}
            onClick={() => {
              if (containerRef.current != null) {
                containerRef.current.scrollTo({
                  top: containerRef.current.scrollTop - SCROLL_HEIGHT,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {showUpArrow && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="18 15 12 9 6 15"></polyline>
              </svg>
            )}
          </button>
        )}
        <div
          onScroll={handleScroll}
          ref={containerRef}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={cn('hide-scrollbar flex  flex-col gap-3 overflow-y-auto', {
            'max-h-[calc((54px*4)+(12px*3))]': shouldDisplayNavigation,
          })}
        >
          {images.map((image: any, i) => (
            <button
              type="button"
              onClick={() => {
                slideTo(i);
              }}
              className={cn(
                'rounded-md border p-1.5 transition duration-150 ease-in-out',
                {
                  'border-gray-700': selectedImage === i,
                  'border-gray-500 opacity-50 hover:opacity-100':
                    selectedImage !== i,
                },
              )}
              key={`image_${i}`}
            >
              <div className="h-10 w-10">
                <Image
                  alt={
                    i === 0
                      ? defaultAltTag ?? image.url ?? image
                      : image.url ?? image
                  }
                  src={image.url || image}
                  width={50}
                  height={50}
                  className="h-full w-full object-contain"
                />
              </div>
            </button>
          ))}
        </div>
        {shouldDisplayNavigation && (
          <button
            className="flex h-12 w-12 items-center justify-center"
            disabled={!showBottomArrow}
            onClick={() => {
              if (containerRef.current != null) {
                containerRef.current.scrollTo({
                  top: containerRef.current.scrollTop + SCROLL_HEIGHT,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {showBottomArrow && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            )}
          </button>
        )}
      </div>
    </>
  );
};

export default function ProductImageSlider(props: {
  images: Array<{ url: string }> | Array<string>;
  intialSlide?: number;
  defaultAltTag?: string;
}) {
  const [selectedImage, setSelectedImage] = useState(0);
  const { images = [], defaultAltTag } = props;
  const { swiper, setSwiper, prevRef, nextRef } = useSwiperSlider();

  useEffect(() => {
    swiper?.slideTo((props.intialSlide as number) || 0);
  }, [props.intialSlide]);

  const arrowCss =
    'absolute top-1/2 z-20 w-8 lg:w-10 h-8 lg:h-10 fill-primary transition-all duration-500 -translate-y-1/2 cursor-pointer';

  const handleArrows = (isBeginning: boolean, isEnd: boolean) => {
    prevRef?.current?.classList.remove('hidden');
    nextRef?.current?.classList.remove('hidden');
    if (isBeginning) prevRef.current.classList.add('hidden');

    if (isEnd) nextRef.current.classList.add('hidden');
  };

  return (
    <div className="flex items-center">
      {images.length > 1 && (
        <Thumbnails
          images={images}
          slideTo={(i) => swiper?.slideTo(i)}
          selectedImage={selectedImage}
          defaultAltTag={defaultAltTag}
        />
      )}
      <div className="relative mx-auto mb-3 flex h-48 w-48 items-center justify-center xs:mb-2 sm:h-72 sm:w-72 lg:mb-0 xl:h-80 xl:w-80">
        <Swiper
          updateOnWindowResize
          observer
          observeParents
          className="mb-4 mt-3 h-full xl:mb-0 xl:mt-0"
          onSwiper={setSwiper}
          onSlideChange={(swiperObj) => {
            setSelectedImage(swiperObj.activeIndex);
            handleArrows(swiperObj.isBeginning, swiperObj.isEnd);
          }}
        >
          {images.map((image: any, i) => (
            <SwiperSlide key={image?.url || i} tabIndex={0}>
              <Image
                src={image.url || image}
                alt={
                  i === 0
                    ? defaultAltTag ?? image.url ?? image
                    : image.url ?? image
                }
                width={300}
                height={300}
                className="h-full min-h-full w-full min-w-full object-contain"
                key={`image-${image.url || image}`}
                loading={i === 0 ? 'eager' : 'lazy'}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="lg:hidden">
          <div
            ref={prevRef}
            className={`-left-10 xxs:-left-1/3 ${arrowCss} ${
              (images.length === 1 || images.length >= 1) && 'hidden'
            }`}
          >
            <div className="flex h-full w-full select-none items-center">
              <svg className="h-full w-full" viewBox="0 0 100 100">
                <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path>
              </svg>
            </div>
          </div>
          <div
            ref={nextRef}
            className={`-right-10 xxs:-right-1/3 ${arrowCss} ${
              images.length === 1 && 'hidden'
            }`}
          >
            <div className="flex h-full w-full items-center justify-center">
              <svg viewBox="0 0 100 100" className="h-full w-full">
                <path
                  d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                  transform="translate(100, 100) rotate(180) "
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
