import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { generateSelectDeviceTitle } from '@/lib/buyback/helpers';
import { PageType, StreamlinedDeviceT } from '@/lib/buyback/types';
import { getEdgeUrl } from '@/lib/getEdgeUrl';
import arrowRightIcon from '@/public/icons/buyback/sell-device/arrow-right.svg';
import howToIcon from '@/public/icons/buyback/sell-device/how-to.svg';
import { BuybackUSConfig } from '@/settings/configs/buyback/us/type';
import ProgressBar from '../ProgressBar';
import FindModel from './FindModel';

const DefaultImage = ({ src, title }: { src: string; title: string }) => (
  <Image
    alt={title}
    src={src}
    height={100}
    width={100}
    quality={100}
    className="h-full w-auto object-contain"
    loading="lazy"
  />
);
const BrandImage = ({ src, title }: { src: string; title: string }) => (
  <Image
    alt={title}
    src={src}
    height={100}
    width={100}
    quality={100}
    className="h-full w-auto object-contain grayscale"
    loading="lazy"
  />
);

const CardContent = ({
  listing,
  pageType,
  searchInput,
}: {
  listing: StreamlinedDeviceT;
  pageType: PageType;
  searchInput: string;
}) => {
  const highlightMatch = (text: string, query: string) => {
    const index = text.toLowerCase().indexOf(query.toLowerCase());

    if (index === -1) return text;

    const beforeMatch = text.substring(0, index);
    const match = text.substring(index, index + query.length);
    const afterMatch = text.substring(index + query.length);

    return (
      <>
        {beforeMatch}
        <strong>{match}</strong>
        {afterMatch}
      </>
    );
  };

  return (
    <Fragment>
      <div className="flex items-center gap-4">
        <div className="flex h-14 w-14 items-center justify-center">
          {pageType === PageType.BRAND ? (
            <BrandImage
              src={getEdgeUrl(
                `images/buyback/brands/${listing.text.toLowerCase()}.svg`,
              )}
              title={listing.text}
            />
          ) : (
            <DefaultImage src={listing.image} title={listing.text} />
          )}
        </div>
        <span>{highlightMatch(listing.text, searchInput)}</span>
      </div>
      <div className="flex h-8 w-8 items-center justify-center">
        <Image
          alt={listing.text}
          src={arrowRightIcon}
          height={50}
          width={50}
          quality={100}
          className="h-full w-auto object-contain"
        />
      </div>
    </Fragment>
  );
};

const Card = ({
  listing,
  pageType,
  searchInput,
  nextStep,
}: {
  listing: StreamlinedDeviceT;
  pageType: PageType;
  searchInput: string;
  nextStep?: (handle: string) => void;
}) => {
  if (!listing.link) return null;

  if (nextStep) {
    return (
      <button
        onClick={() => nextStep(listing?.link ?? '')}
        type="button"
        className="flex cursor-pointer items-center justify-between rounded-md border-2 p-4 transition duration-150 ease-in-out hover:bg-gray-300"
      >
        <CardContent
          listing={listing}
          pageType={pageType}
          searchInput={searchInput}
        />
      </button>
    );
  }

  return (
    <Link
      href={
        pageType === PageType.MODEL
          ? `/buyback/${listing.link}/device`
          : `${listing.link}`
      }
    >
      <a className="flex cursor-pointer items-center justify-between rounded-md border-2 p-4 transition duration-150 ease-in-out hover:bg-gray-300">
        <CardContent
          listing={listing}
          pageType={pageType}
          searchInput={searchInput}
        />
      </a>
    </Link>
  );
};

const lowerCaseMatch = (text: string, match: string) =>
  text.toLowerCase().includes(match.toLowerCase());

const SelectDevice = ({
  device,
  pageType,
  progress,
  tradeIn,
}: {
  device: BuybackUSConfig;
  pageType: PageType;
  progress?: {
    step: number;
    steps: number;
  };
  tradeIn?: {
    nextStep: (handle: string) => void;
    previousStep: (() => void) | undefined;
  };
}) => {
  const router = useRouter();
  const viewTitle = generateSelectDeviceTitle(pageType);

  const [showFindModelModal, setShowFindModelModal] = useState(false);
  const [listings, setListings] = useState<StreamlinedDeviceT[]>(
    device.content as StreamlinedDeviceT[],
  );
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setListings(device.content as StreamlinedDeviceT[]);
    setSearchInput('');
  }, [device]);

  useEffect(() => {
    if (searchInput === '') {
      setListings(device.content as StreamlinedDeviceT[]);
    } else {
      const filteredListings = (device.content as StreamlinedDeviceT[]).filter(
        (d) => lowerCaseMatch(d.text, searchInput),
      );

      setListings(filteredListings);
    }
  }, [searchInput]);

  const showHowTo =
    pageType === PageType.MODEL &&
    device.title === 'Apple' &&
    device.category === 'phone';

  const searchPlaceholder = (() => {
    const firstTwoResults = (device?.content as StreamlinedDeviceT[])
      ?.slice(0, 2)
      .map((d) => d.text);

    if (firstTwoResults.length)
      return `Search ex: ${firstTwoResults.join(', ')}...`;

    return `Search`;
  })();

  return (
    <>
      {tradeIn?.previousStep && (
        <button
          type="button"
          onClick={() => tradeIn.previousStep && tradeIn.previousStep()}
          className="flex items-center gap-2 font-bold text-[#B1B2B2]"
        >
          <div className="h-6 w-6">
            <Image
              alt="Back"
              src={arrowRightIcon}
              height={50}
              width={50}
              quality={100}
              className="h-full w-auto rotate-180 object-contain"
            />
          </div>
          <span className="block">Back</span>
        </button>
      )}
      {progress && (
        <ProgressBar
          pageType={pageType}
          back={() => router.back()}
          step={progress.step}
          steps={progress.steps}
          category={device.category ?? ''}
        />
      )}
      <div
        className={cn({
          'mt-8': progress,
          'mt-4': tradeIn?.previousStep,
        })}
      >
        <div className="flex flex-wrap items-center justify-between gap-x-4">
          <h4 className="text-lg font-bold">{viewTitle}</h4>
          {showHowTo && (
            <button
              type="button"
              className="flex items-center gap-2 font-bold text-[#497B7D] underline"
              onClick={() => setShowFindModelModal(true)}
            >
              <div className="mt-[-1px] flex h-4 w-4 shrink-0 items-center justify-center">
                <Image
                  alt="How to find this"
                  src={howToIcon}
                  height={50}
                  width={50}
                  quality={100}
                  className="h-full w-auto object-contain"
                />
              </div>
              How to find this?
            </button>
          )}
        </div>
        <div className="mt-2">
          <input
            className="w-full rounded-md bg-gray-200 p-4 outline-none"
            type="text"
            placeholder={searchPlaceholder}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
          />
        </div>
        <div className="mt-4 flex flex-col gap-2">
          {listings.map((listing) => (
            <Card
              nextStep={tradeIn?.nextStep}
              listing={listing}
              key={listing.text}
              pageType={pageType}
              searchInput={searchInput}
            />
          ))}
        </div>
      </div>
      <FindModel
        showModal={showFindModelModal}
        setShowModal={setShowFindModelModal}
      />
    </>
  );
};

export default SelectDevice;
