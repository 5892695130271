import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import Image from 'next/future/image';
import { priceFormater } from '@/settings';
import { ReplacedProduct, VARIANTS } from '@/lib/collections/catalogue/helpers';
import PriceInformation from '@/components/products/PriceInformation';
import PurchaseLinks, {
  ProductDetails,
} from '@/components/products/PurchaseLinks';
import { priceFormatSuperscript } from '@/lib/price';
import { ShippingData } from './types';
import searchIcon from '@/public/icons/menu/search.svg';
import { ProductSource } from '@/lib/analytics/types';

// Function to highlight text
function highlightText(text: string, search: string) {
  const regex = new RegExp(search, 'gi');

  return text.replace(
    regex,
    (match) => `<span class="bg-yellow">${match}</span>`,
  );
}

export default function SpecificationsModal(props: {
  productName: string;
  specifications: { key: string; value: string }[];
  show: boolean;
  onClose: () => void;
  comparePrice?: number;
  price?: number;
  specs: Record<string, unknown>;
  variantId: number;
  productId: number;
  warranty: number | null;
  shippingData: ShippingData;
  deliverBy?: { min: string; max: string };
  subsidyTag?: string;
  isOutOfStock?: boolean;
  upgradation?: string;
  vendor: string;
  offer?: ReplacedProduct;
  productDetails?: ProductDetails;
  category?: string;
}) {
  const { specifications, comparePrice, price } = props;

  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-[50] overflow-hidden"
      onClose={props.onClose}
      open={props.show}
    >
      <div className="min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-gray-700/[.3]" />
        <div className="absolute right-0 inline-block h-full w-full overflow-auto bg-white text-left align-middle shadow-xl transition-all xl:w-10/12 2xl:w-1/3">
          <div className="sticky top-0 bg-white p-4">
            <Dialog.Title
              as="h3"
              className="hidden py-4 text-lg font-bold leading-6 text-gray-700 sm:block"
            >
              Specifications
            </Dialog.Title>

            <h1
              className="w-3/4 text-lg font-bold leading-6 text-gray-700 sm:hidden"
              id="e2e-product-name"
            >
              {props.productName}
            </h1>
            <p className="w-3/4 text-xs text-gray-700 sm:hidden">
              {VARIANTS.map((v) => props.specs[v])
                .filter((v) => v)
                .join(' | ')
                .replace('Very Good', 'Good')}
            </p>
            <button
              onClick={props.onClose}
              type="button"
              className="btn-close absolute right-6 top-[50%] -translate-y-1/2 opacity-100 md:h-3 md:w-3"
              aria-label="Close"
            ></button>
          </div>

          <div className="flex flex-col bg-white pb-[5rem]">
            <div
              className={`mx-auto mb-4 flex items-center rounded border bg-gray-200 p-2 text-sm text-gray-700 md:w-96 ${
                isFocused ? 'border-blue-300' : ''
              }`}
            >
              <input
                placeholder="Filter specifications"
                className="flex-1 bg-transparent outline-none"
                aria-label="Enter email to subscribe"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              {search.length > 0 ? (
                <button
                  onClick={() => setSearch('')}
                  type="button"
                  className="mr-1 w-5 font-semibold text-gray-700 sm:mr-2 sm:text-sm"
                  aria-label="Close"
                >
                  X
                </button>
              ) : (
                <div className="mr-1 h-5 w-5 sm:mr-2">
                  <Image
                    src={searchIcon}
                    alt="search icon"
                    width="20"
                    height="20"
                    sizes="100vw"
                    className="w-full"
                  />
                </div>
              )}
            </div>
            {specifications
              .filter((item) =>
                item.key.toLowerCase().includes(search.toLocaleLowerCase()),
              )
              .map((item, index) => (
                <div
                  className="flex flex-col border-t-2 p-2 text-sm even:bg-[#F2F2F2]"
                  key={`${index}_${item.key}`}
                >
                  <h3
                    className="w-full px-2 pt-2 font-semibold "
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: highlightText(item.key, search),
                    }}
                  />
                  {item?.value && (
                    <p className="flex-1 px-2 pb-2 md:p-2">
                      {item.value
                        .split('\\n')
                        .filter(Boolean)
                        .map((v, i) => (
                          <div key={`specifications_value_${i}`}>{v}</div>
                        ))}
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className="fixed bottom-0 z-50 mt-4 flex w-full items-center gap-3 border-t border-gray-500 bg-white px-5 pb-4 pt-2.5 shadow-[0_-4px_9px_rgba(0,0,0,0.05)] sm:right-0 xl:w-10/12 2xl:w-1/3"
        id="purchaseCont"
      >
        <div className="flex w-1/2 flex-col items-start">
          {comparePrice ? (
            <PriceInformation
              actualPrice={price}
              comparePrice={comparePrice}
              view={'mobileBottom'}
            />
          ) : (
            <div className="flex flex-col items-start justify-end gap-x-2 xxs:flex-row xxs:items-center">
              <p className="block font-bold text-gray-700 xs:text-xl">
                {priceFormatSuperscript(priceFormater.long(price || 0))}
              </p>
            </div>
          )}
        </div>
        <div className="w-1/2">
          <PurchaseLinks
            offer={props.offer}
            vendor={props.vendor}
            category={props.category}
            warranty={props.warranty}
            shippingData={props.shippingData}
            deliverBy={props.deliverBy}
            subsidyTag={props.subsidyTag}
            productId={props.productId}
            variantId={props.variantId}
            cartButtonVariant="dark"
            upgradation={props.upgradation}
            productDetails={props.productDetails}
            isOutOfStock={props.isOutOfStock}
            comparePrice={comparePrice}
            className="grid w-full grid-cols-1 gap-1.5 py-0.5"
            productSource={ProductSource.SPECS}
          />
        </div>
      </div>
    </Dialog>
  );
}
