import useSWR from 'swr';
import type { ExpressShippingOfferResponse } from '@/api/express-shipping/offer';

export default function useExpressShippingOffer({
  vendor,
  category,
  isEnabled,
}: {
  vendor: string;
  category: string;
  isEnabled: boolean;
}) {
  const response = useSWR<ExpressShippingOfferResponse>(() =>
    isEnabled
      ? `/api/express-shipping/offer?vendor=${encodeURIComponent(
          vendor,
        )}&category=${category}`
      : null,
  );

  return response.data;
}
