import cn from 'classnames';
import Image from 'next/future/image';
import i18n from '@/settings/i18n';
import settings from '@/settings';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const t = i18n.init();

export default function PaymentIcons({
  hideTitle,
  largeBox,
  largerIcon,
}: {
  hideTitle?: boolean;
  largeBox?: boolean;
  largerIcon?: boolean;
}) {
  const showTitle = !hideTitle;

  return (
    <div
      className={cn('', {
        'w-full': showTitle,
        border: largeBox,
      })}
    >
      {showTitle && (
        <div
          className={cn(
            'mb-2 flex w-full items-center text-left text-xs font-bold',
            {
              '!mb-0 bg-gray-100 px-5 py-4': largeBox,
            },
          )}
        >
          <div
            className={cn({
              'relative h-[15px] w-[12px]': !largeBox,
              'relative h-[20px] w-[16px]': largeBox,
            })}
          >
            <Image
              src={getEdgeUrl(`icons/key_features/secure-payment.svg`)}
              alt="Secure payment"
              className="h-full w-full object-fill"
              height={15}
              width={12}
            />
          </div>
          <span
            className={cn('ml-1 translate-y-px', {
              '!ml-3 text-sm uppercase': largeBox,
            })}
          >{t`100% Secure Payments`}</span>
        </div>
      )}
      <div
        className={cn('flex flex-wrap gap-1', {
          '!gap-3 px-5 py-3': largeBox,
          '!gap-[8px] xxs:!gap-[8px] sm:!gap-3 lg:!gap-4': largerIcon,
        })}
      >
        {Object.keys(settings.payment_methods).map((p) => (
          <div
            key={p}
            className={cn({
              'relative h-[16px] w-[25px]': !largeBox,
              'relative h-[24px] w-[35px]': largeBox,
              'relative h-[19px] w-[27px] xxs:h-[22px] xxs:w-[31px] sm:!h-[25px] sm:!w-[38px]':
                largerIcon,
            })}
          >
            <Image
              src={getEdgeUrl(`icons/payments/${p}.svg`)}
              title={settings.payment_methods[p]}
              alt={p}
              height={15}
              width={12}
              className="h-full w-full object-fill"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
